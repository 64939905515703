import { globalFontStyle as GlobalFonts } from './assets/fonts/font-faces';
import { ThemeProvider } from 'styled-components';
import { theme } from './globalConfig/styling/theme';
import { globalStyle as GlobalStyle } from './globalConfig/styling/globalStyle';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import { AuthedRoute } from './auth/Auth';
import { AuthProvider } from './auth/AuthedContext';
import { Unauthorized } from './errorPages/Unauthorized';
import { OrderInfoErrorPage } from './common/OrderInfoErrorPage';
import { OrderComplete } from './giftComplete/OrderComplete';
import { ReceiveGift } from './receiveGift/ReceiveGift';
import { NavBar } from './nav/Navbar';
import { OrderDetails } from './orderDetails/OrderDetails';
import { OrdersList } from './ordersList/OrdersList';
import { RescheduleContent } from './rescheduleOrder/RescheduleContent';
import { BookOrder } from './booker/BookOrder';

export const Root = (): JSX.Element => {
  document.title = 'My TOSHI';
  return (
    <ThemeProvider theme={theme}>
      <GlobalFonts />
      <GlobalStyle />
      <BrowserRouter>
        <AuthProvider>
          {/* Routes go here in order from most to least specific */}
          <NavBar />
          <Switch>
            <Route exact path="/unauthorized" component={Unauthorized} />
            <Route exact path="/gifting/error" component={OrderInfoErrorPage} />
            <Route exact path="/orderComplete" component={OrderComplete} />
            <AuthedRoute path={'/gifting/:id'} exact>
              <ReceiveGift />
            </AuthedRoute>
            <AuthedRoute path="/orders/:id" exact>
              <OrderDetails />
            </AuthedRoute>
            <AuthedRoute path="/orders/:id/book" exact>
              <BookOrder />
            </AuthedRoute>
            <AuthedRoute path="/orders/:id/reschedule" exact>
              <RescheduleContent />
            </AuthedRoute>
            <AuthedRoute path="/orders" exact>
              <OrdersList />
            </AuthedRoute>
          </Switch>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
