export const authEndpoint = 'v2/mytoshi/auth';
export type AuthResponseType = {
  ok: 'true' | 'false';
};

export const postAddressEligibleEndpoint = (orderId: string): string =>
  `v2/gifts/orders/${orderId}/postcode_verifications`;
export type PostAddressEligibleResp = {
  eligible: boolean;
};

export const addressAutocompleteEndpoint = 'v2/gifts/address_searches';
export interface AddressAutocompleteResult {
  address_components: {
    address_line_1: string;
    address_line_2: string | null;
    town: string | null;
    province: string;
    postcode: string;
    country: string;
  };
}
export type AddressAutocompleteResponse = {
  predictions: AddressAutocompleteResult[];
};

export const getOrderInfoEndpoint = 'v2/gifts/orders';
export type PersonResponse =
  | {
      title: string;
      last_name: string;
    }
  | PersonNameSurname;

export type PersonNameSurname = {
  first_name: string;
  last_name: string;
};

export type OrderItemResponse = {
  quantity: number;
  was_returned: boolean;
  name: string;
  size: string;
  id: string;
  image_url?: string;
};

export type CustomerResponse = {
  id: string;
  email: string | null;
  contact_number: string | null;
  last_address: AddressResponse;
  contact_number_country: string;
  contact_preferences: number;
} & PersonResponse;

export type AddressResponse = {
  id: number;
  house_name: string | null;
  line_1: string;
  line_2: string | null;
  city_town: string;
  postcode_zipcode: string;
  latitude: number;
  longitude: number;
  notes: string | null;
  closest_zone: string | null;
  zones: string | null;
};

export type JourneyTransitionResponse = {
  id: number;
  to_state: AnyJourneyTransitionStatus;
  sort_key: number;
  journey_id: string;
  most_recent: boolean;
  created_at: string;
  updated_at: string;
};

export type JourneyResponse = {
  id: string;
  delivery_slot_id: number;
  order_id: string;
  start_address_id: number;
  end_address_id: number;
  type: 'DeliveryJourney' | 'ReturnJourney';
  start_datetime: string;
  end_datetime: string;
  start_address: AddressResponse;
  end_address: AddressResponse;
  order_item_ids: string[];
  status: string;
  assistant_id: number;
  assistant_time_to_store: number;
  store_id: number;
  security_code_verified: boolean;
  use_security_codes: boolean;
  utc_offset_seconds: number;
  in_terminal_state: boolean;
  journey_transitions: JourneyTransitionResponse[];
};

export type GetOrderInfoResponse = {
  order: {
    id: string;
    gift_receiver: PersonResponse | null;
    gift_sender: PersonResponse | null;
    brand_name: string;
    store_location: 'LDN' | 'NYC' | string;
    brand_logo_url?: string;
    store_id: number;
    gift_message?: string;
    order_items: OrderItemResponse[];
    delivery_info: {
      booking_date: string;
      booking_timeslot_start: string;
      booking_timeslot_end: string;
      delivery_address: string;
    } | null;
    order_interface: string;
    journeys?: JourneyResponse[];
    utc_offset_seconds: number;
    services: {
      dropoff: {
        enabled: boolean;
      };
      wait_and_try: {
        enabled: boolean;
      };
      inspire_me: {
        enabled: boolean;
        text?: string;
      };
      up_down_size: {
        enabled: boolean;
      };
      perfect_fit: {
        enabled: boolean;
      };
      alteration: {
        enabled: boolean;
      };
    };
    customer: CustomerResponse;
    unscheduled_order_address: AddressResponse | null;
  };
};

export type OrderItem = {
  quantity: number;
  wasReturned: boolean;
  name: string;
  size: string;
  id: string;
  imageUrl?: string;
};

export type AddressTransformedResponse = {
  id: number;
  houseName: string | null;
  line1: string;
  line2: string | null;
  cityTown: string;
  postcodeZipcode: string;
  latitude: number;
  longitude: number;
  notes: string | null;
  closestZone: string | null;
  zones: string | null;
};

type SharedJourneyTransitionStatus = 'scheduled' | 'collected_items' | 'arrived_at_client' | 'completed' | 'cancelled';

export type ReturnJourneyTransitionStatus = SharedJourneyTransitionStatus;

export type DeliveryJourneyTransitionStatus =
  | SharedJourneyTransitionStatus
  | 'rescheduled'
  | 'arrived_at_store'
  | 'serviced'
  | 'abandoned_ecom';

export type AnyJourneyTransitionStatus = DeliveryJourneyTransitionStatus | ReturnJourneyTransitionStatus;

export type JourneyTransitionTransformedResponse = {
  id: number;
  toState: AnyJourneyTransitionStatus;
  sortKey: number;
  journeyId: string;
  mostRecent: boolean;
  createdAt: string;
  updatedAt: string;
};

export type JourneyTransformedResponse = {
  id: string;
  deliverySlotId: number;
  orderId: string;
  startAddressId: number;
  endAddressId: number;
  type: 'DeliveryJourney' | 'ReturnJourney';
  startDatetime: string;
  endDatetime: string;
  startAddress: AddressTransformedResponse;
  endAddress: AddressTransformedResponse;
  orderItemIds: string[];
  status: string;
  assistantId: number;
  assistantTimeToStore: number;
  storeId: number;
  securityCodeVerified: boolean;
  useSecurityCodes: boolean;
  utcOffsetSeconds: number;
  inTerminalState: boolean;
  journeyTransitions: JourneyTransitionTransformedResponse[];
};

export type PersonTransformedResponse =
  | {
      title: string;
      lastName: string;
    }
  | PersonTransformedNameSurname;

export type PersonTransformedNameSurname = {
  firstName: string;
  lastName: string;
};

export type CustomerTransformedResponse = {
  id: string;
  name: string;
  email: string | null;
  contactNumber: string | null;
  lastAddress: AddressResponse;
  contactNumberCountry: string;
  contactPreferences: number;
};

export type GetOrderInfoTransformedResponse = {
  id: string;
  giftReceiver: string | null;
  giftSender: string | null;
  brandName: string;
  storeLocation: 'LDN' | 'NYC' | string;
  brandLogoUrl?: string;
  storeId: number;
  giftMessage?: string;
  orderItems: OrderItem[];
  deliveryInfo: {
    bookingDate: string;
    bookingTimeslotStart: string;
    bookingTimeslotEnd: string;
    deliveryAddress: string;
  } | null;
  orderInterface: string;
  journeys?: JourneyTransformedResponse[];
  utcOffsetSeconds: number;
  services: {
    dropoff: {
      enabled: boolean;
    };
    waitAndTry: {
      enabled: boolean;
    };
    inspireMe: {
      enabled: boolean;
      text?: string;
    };
    upDownSize: {
      enabled: boolean;
    };
    perfectFit: {
      enabled: boolean;
    };
    alteration: {
      enabled: boolean;
    };
  };
  customer: CustomerTransformedResponse;
  unscheduledOrderAddress: AddressTransformedResponse | null;
};

export const getAvailabilityEndpoint = 'v2/availability/check_available';
export type GetAvailabilityParams = {
  address_line_1: string;
  address_line_2: string;
  town: string;
  postcode: string;
  store_id: number;
  interface: string;
  slots_for_dates?: string[];
};
export type GetAvailabilityResponse = {
  available: boolean;
  order_hold_minutes: number;
  dates: {
    [key: string]: {
      delivery_slot_id: number;
      start_time: string;
      end_time: string;
      delivery_date: string;
      available: boolean;
    }[];
  };
};

type ScheduledAddress = {
  line_1: string;
  postcode_zipcode: string;
  house_name?: string;
  line_2?: string;
  city_town?: string;
};

export const submitScheduleGiftFormEndpoint = (orderId: string): string => `v2/gifts/orders/${orderId}/schedule`;
export type SubmitScheduleGiftFormBody = {
  order_id: string;
  scheduled_date: string;
  delivery_slot_id: string;
  toshi_updates?: boolean;
  address: ScheduledAddress;
};
export type SubmitScheduleGiftFormResponse = {
  status: number;
};

export const generateReauthEmailEndpoint = 'v2/mytoshi/reauth';

const v3MyToshiBaseUrl = 'api/v3/my_toshi';

export const rescheduleEndpoint = (orderId: string): string =>
  `${v3MyToshiBaseUrl}/orders/${orderId}/rescheduled_delivery_journeys`;
export type rescheduleBody = {
  delivery_journey: {
    delivery_slot_id: string;
    delivery_date: string;
  };
  services?: {
    wait_and_try: {
      enabled: boolean;
    };
    inspire_me: {
      enabled: boolean;
      text?: string;
    };
  };
  toshi_updates?: boolean;
  address?: ScheduledAddress;
};
export type RescheduleResponse = {
  journey: JourneyResponse;
};

export const bookerEndpoint = (orderId: string): string => `${v3MyToshiBaseUrl}/orders/${orderId}/delivery_journeys`;
export type bookerBody = {
  delivery_journey: {
    delivery_slot_id: string;
    delivery_date: string;
  };
  services: {
    wait_and_try: {
      enabled: boolean;
    };
    dropoff: {
      enabled: boolean;
    };
    inspire_me: {
      enabled: boolean;
      text?: string;
    };
  };
  toshi_updates?: boolean;
};
export type BookerResponse = {
  journey: JourneyResponse;
};
