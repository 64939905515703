import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { BrandLogo } from '../brandLogo/BrandLogo';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { StyledParagraphLarge, StyledParagraphMedium } from '../common/typography/Typography';
import { useTranslations } from '../localisation/translateText';
import { useGetData } from '../network/api/api';
import {
  getOrderInfoEndpoint,
  GetOrderInfoResponse,
  GetOrderInfoTransformedResponse,
  JourneyTransformedResponse,
} from '../network/api/endpoints';
import { transformOrderDetails } from '../network/transformers/transformOrderDetails';
import { OrderInfoErrorPage } from '../common/OrderInfoErrorPage';
import { translations, translationsType } from './translations';
import { OrderHelp } from '../orderHelp/OrderHelp';
import { SecondaryCTA } from '../common/buttons/buttons';
import { DeliveryDetails } from './DeliveryDetails';
import { ToshiAssistantUpdates } from './ToshiAssistantUpdates';
import { OrderServices } from './OrderServices';
import { DeliveryInfo } from './DeliveryInfo';
import { FullWidthPaddedColumn } from '../common/Containers';
import { breakPoint2XL, breakPointLG, breakPointXL } from '../globalConfig/styling/breakPoints';
import useMatchMedia from 'use-match-media-hook';
import { OrderItems } from '../common/orderItems/OrderItems';

const SectionWrapper = styled.div`
  margin-bottom: 25px;
`;

const BrandNameIdContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FadedParagraphMedium = styled(StyledParagraphMedium)`
  color: ${(props) => props.theme.fontColorFaded};
`;

interface FirstSectionProps {
  mostRecentDeliveryJourney: JourneyTransformedResponse | undefined;
  orderData: GetOrderInfoTransformedResponse;
  translationsContent: translationsType;
  orderId: string;
  orderDelivered: boolean;
}
const FirstSection = ({
  mostRecentDeliveryJourney,
  orderData,
  translationsContent,
  orderId,
  orderDelivered,
}: FirstSectionProps): JSX.Element => {
  const history = useHistory();

  return (
    <>
      <BrandNameIdContainer>
        <StyledParagraphLarge bold>{orderData.brandName}</StyledParagraphLarge>
        <FadedParagraphMedium>#{orderId}</FadedParagraphMedium>
      </BrandNameIdContainer>
      <SectionWrapper>
        <DeliveryDetails
          order={orderData}
          mostRecentDeliveryJourney={mostRecentDeliveryJourney}
          translations={translationsContent}
        />
      </SectionWrapper>
      {!orderDelivered && (
        <SectionWrapper>
          {mostRecentDeliveryJourney && (
            <SecondaryCTA onClick={() => history.push(`/orders/${orderId}/reschedule`)}>
              {translationsContent.rescheduleYourDelivery}
            </SecondaryCTA>
          )}
          {!orderData.deliveryInfo && orderData.giftReceiver && (
            <SecondaryCTA onClick={() => history.push(`/gifting/${orderId}`)}>
              {translationsContent.bookYourGiftDelivery}
            </SecondaryCTA>
          )}
          {!orderData.deliveryInfo && !orderData.giftReceiver && (
            <SecondaryCTA onClick={() => history.push(`/orders/${orderId}/book`)}>
              {translationsContent.bookYourOrderDelivery}
            </SecondaryCTA>
          )}
        </SectionWrapper>
      )}
      <SectionWrapper>
        <ToshiAssistantUpdates
          translations={translationsContent}
          transitions={mostRecentDeliveryJourney?.journeyTransitions}
        />
      </SectionWrapper>
      <SectionWrapper>
        <OrderServices orderData={orderData} translationsContent={translationsContent} />
      </SectionWrapper>
      {mostRecentDeliveryJourney && (
        <SectionWrapper>
          <DeliveryInfo
            translations={translationsContent}
            deliveryAddress={mostRecentDeliveryJourney.endAddress}
            orderDelivered={orderDelivered}
          />
        </SectionWrapper>
      )}
    </>
  );
};

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 70px 70px 0 150px;
  gap: 240px;
`;

const OrderHelpWrapper = styled.div`
  margin-top: 20px;
`;

const DesktopLeftSection = styled.div`
  flex: 0.75;

  @media (min-width: ${breakPointXL}px) {
    flex: 0.6;
  }

  @media (min-width: ${breakPoint2XL}px) {
    flex: 0.5;
  }
`;

const DesktopRightSection = styled.div`
  flex: 0.25;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const BottomSection = styled.div`
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const OrderDetails = (): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);
  const urlParams = useParams<{ id: string }>();
  const { data: orderData, error } = useGetData<GetOrderInfoTransformedResponse, GetOrderInfoResponse>(
    `${getOrderInfoEndpoint}/${urlParams.id}`,
    (value) => transformOrderDetails(value),
  );

  const queries = [`(min-width: ${breakPointLG}px)`];
  const [desktop] = useMatchMedia(queries);

  if (error && Object.keys(error).length > 0) {
    return <OrderInfoErrorPage />;
  }

  if (!orderData) {
    return <LoadingSpinner />;
  }

  const deliveryJourneys = orderData?.journeys
    ?.filter((journey) => journey.type === 'DeliveryJourney' && journey.status !== 'pending')
    ?.sort(
      (journeyA, journeyB) => new Date(journeyA.startDatetime).getTime() - new Date(journeyB.startDatetime).getTime(),
    )
    ?.reverse();
  const mostRecentDeliveryJourney = deliveryJourneys?.length ? deliveryJourneys[0] : undefined;
  const lastTransition =
    mostRecentDeliveryJourney?.journeyTransitions[mostRecentDeliveryJourney?.journeyTransitions.length - 1];
  const orderDelivered = Boolean(mostRecentDeliveryJourney && lastTransition?.toState === 'completed');

  if (desktop) {
    return (
      <DesktopWrapper>
        <DesktopLeftSection>
          <FirstSection
            mostRecentDeliveryJourney={mostRecentDeliveryJourney}
            orderData={orderData}
            orderDelivered={orderDelivered}
            orderId={urlParams.id}
            translationsContent={translationsContent}
          />
        </DesktopLeftSection>
        <DesktopRightSection>
          <BrandLogo brandName={orderData.brandName} brandLogoUrl={orderData.brandLogoUrl} />
          <OrderItems orderData={orderData} />
          <OrderHelpWrapper>
            <OrderHelp />
          </OrderHelpWrapper>
        </DesktopRightSection>
      </DesktopWrapper>
    );
  }

  return (
    <>
      <BrandLogo mobile brandName={orderData.brandName} brandLogoUrl={orderData.brandLogoUrl} />
      <FullWidthPaddedColumn>
        <FirstSection
          mostRecentDeliveryJourney={mostRecentDeliveryJourney}
          orderData={orderData}
          orderDelivered={orderDelivered}
          orderId={urlParams.id}
          translationsContent={translationsContent}
        />
      </FullWidthPaddedColumn>
      <BottomSection>
        <OrderItems orderData={orderData} />
        <OrderHelp />
      </BottomSection>
    </>
  );
};
