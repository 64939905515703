export const getEnvironment = (): 'next' | 'staging' | 'production' | 'development' => {
  switch (window.location.host) {
    case 'next.my.toshi.co':
      return 'next';
    case 'staging.my.toshi.co':
      return 'staging';
    case 'my.toshi.co':
      return 'production';
    case 'my.toshi.test:8081':
      return 'development';
    default:
      return 'development';
  }
};
