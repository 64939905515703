import {
  ChevronDownIcon as HeroIconsChevronDown,
  ChevronRightIcon as HeroIconsChevronRight,
  ShoppingBagIcon,
} from '@heroicons/react/outline';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { StyledParagraphMedium } from '../common/typography/Typography';
import { GetOrderInfoTransformedResponse } from '../network/api/endpoints';
import { GridWrapper, StatusUpdate, WhiteBlock } from './SharedStyledComponents';
import { translationsType } from './translations';

const OrderServiceEntry = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding-right: 5px;
`;
const ChevronIconStyle = css`
  background-color: ${(props) => props.theme.whiteColor};
  vertical-align: middle;
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;
const ChevronRightIcon = styled(HeroIconsChevronRight)`
  ${ChevronIconStyle}
`;
const ChevronDownIcon = styled(HeroIconsChevronDown)`
  ${ChevronIconStyle}
`;

interface OrderServiceProps {
  serviceTitle: string;
  serviceDescription: string;
}
const OrderService = ({ serviceTitle, serviceDescription }: OrderServiceProps) => {
  const [serviceOpen, setServiceOpen] = useState(false);
  return (
    <>
      <div></div>
      <WhiteBlock>
        <OrderServiceEntry>
          <StatusUpdate>{serviceTitle}</StatusUpdate>
          {!serviceOpen && (
            <ChevronRightIcon
              role="img"
              aria-hidden={false}
              onClick={() => setServiceOpen(true)}
              aria-label={`Expand ${serviceTitle} description`}
            />
          )}
          {serviceOpen && (
            <ChevronDownIcon
              role="img"
              aria-hidden={false}
              onClick={() => setServiceOpen(false)}
              aria-label={`Collapse ${serviceTitle} description`}
            />
          )}
        </OrderServiceEntry>
        {serviceOpen && <StatusUpdate>{serviceDescription}</StatusUpdate>}
      </WhiteBlock>
    </>
  );
};

const BagIconWrapper = styled.div`
  padding-bottom: 10px;
  vertical-align: middle;
  margin-right: 10px;
`;
interface OrderServicesProps {
  orderData: GetOrderInfoTransformedResponse;
  translationsContent: translationsType;
}

export const OrderServices = ({ orderData, translationsContent }: OrderServicesProps): JSX.Element => {
  return (
    <GridWrapper>
      <BagIconWrapper>
        <ShoppingBagIcon aria-label="Bag icon" width={20} height={20} style={{ alignSelf: 'center' }} />
      </BagIconWrapper>
      <StyledParagraphMedium>{translationsContent.yourToshiServices}</StyledParagraphMedium>
      {orderData.services &&
        (Object.keys(orderData.services) as Array<keyof GetOrderInfoTransformedResponse['services']>).map((service) => {
          if (!orderData.services[service].enabled) {
            return undefined;
          }
          let serviceTitle: string | undefined;
          let serviceDescription: string | undefined;
          switch (service) {
            case 'waitAndTry': {
              serviceTitle = translationsContent.waitAndTry;
              serviceDescription = translationsContent.toshiAssistantWaitWhilstDecide;
              break;
            }
            case 'upDownSize': {
              serviceTitle = translationsContent.sizeUpDown;
              serviceDescription = translationsContent.sizeUpDownDescription;
              break;
            }
            case 'inspireMe': {
              serviceTitle = translationsContent.inspireMe;
              serviceDescription = translationsContent.inspireMeDescription;
              break;
            }
            case 'alteration': {
              serviceTitle = translationsContent.alteration;
              serviceDescription = translationsContent.alterationDescription;
              break;
            }
            case 'perfectFit': {
              serviceTitle = translationsContent.perfectFit;
              serviceDescription = translationsContent.perfectFitDescription;
              break;
            }
          }
          if (serviceTitle === undefined || serviceDescription === undefined) {
            return undefined;
          }
          return <OrderService key={service} serviceTitle={serviceTitle} serviceDescription={serviceDescription} />;
        })}
    </GridWrapper>
  );
};
