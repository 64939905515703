import { useState } from 'react';
import styled from 'styled-components';
import { useLocalStorage } from '../auth/useLocalStorage';
import { StyledInput } from '../common/formComponents/FormComponents';
import { StyledParagraphMedium } from '../common/typography/Typography';
import { MainCTA } from '../common/buttons/buttons';
import { useMutateData } from '../network/api/api';
import { generateReauthEmailEndpoint } from '../network/api/endpoints';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { useLocation } from 'react-router';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  gap: 30px;
  box-sizing: border-box;
  text-align: center;
`;

const SessionEndedP = styled(StyledParagraphMedium)`
  max-width: 50%;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Submit = styled(MainCTA)`
  width: 100%;
`;

export const Unauthorized = (): JSX.Element => {
  const { storedValue: isAuthed, removeValue: removeIsAuthed } = useLocalStorage('isAuthed', null);
  if (isAuthed) {
    removeIsAuthed();
  }

  const [email, setEmail] = useState('');
  const [sentReauth, setSentReauth] = useState(false);
  const [sending, setSending] = useState(false);
  const { mutate } = useMutateData();
  // For now, we are assuming that the only endpoint mytoshi has is the gifting one.
  // Once we have a dashboard, we will be redirecting to that and so won't need this
  // location stuff
  const location = useLocation<{
    path: string;
  }>();
  const { translationsContent } = useTranslations<translationsType>(translations);

  const reauthorise = async () => {
    setSentReauth(false);
    setSending(true);
    const path = location?.state?.path || location.pathname;
    const splitPath = path.split('/');
    await mutate(
      generateReauthEmailEndpoint,
      {
        order_id: splitPath[splitPath.length - 1],
        email: email,
      },
      'POST',
    );
    setSentReauth(true);
    setSending(false);
  };

  return (
    <Wrapper>
      <SessionEndedP>{translationsContent.sessionEnded}</SessionEndedP>
      {!sending && (
        <ActionContainer>
          <StyledInput value={email} onChange={(e) => setEmail(e.target.value)} type="email" />
          <Submit onClick={reauthorise}>{translationsContent.sendEmail}</Submit>
        </ActionContainer>
      )}
      {sentReauth && <div>{translationsContent.sentEmailTo}</div>}
      {sending && <LoadingSpinner />}
    </Wrapper>
  );
};
