import { ChangeEvent } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';
import { StyledInput } from '../common/formComponents/FormComponents';
import { useTranslations } from '../localisation/translateText';
import { SearchResult } from './AddressFields';
import { translations, translationsType } from './translations';

const AddressLookupContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ResultsContainer = styled.div`
  position: absolute;
  top: 78px;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1;
`;

const AddressSelectButton = styled.button`
  background-color: ${(props) => props.theme.lightGrey};
  border: 1px solid black;
  border-top: none;
  padding: 12px 24px 14px;
  text-align: left;
  cursor: pointer;
  margin: 0; // fixes Safari weirdness

  &:hover {
    background-color: ${(props) => props.theme.color};
    color: ${(props) => props.theme.invertColor};
  }
`;

const LabelTextContainer = styled.div`
  padding-bottom: 8px;
`;

interface AddressFinderProps {
  setValue: UseFormSetValue<FieldValues>;
  setManualLookUp: (manualLookup: boolean) => void;
  results: SearchResult[];
  term: string;
  setTerm: (term: string) => void;
}

export const AddressFinder = ({
  results,
  setValue,
  setManualLookUp,
  term,
  setTerm,
}: AddressFinderProps): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);

  const parseSearchResultToString = (result: SearchResult): string => {
    return Object.values(result)
      .filter((value) => value !== null)
      .join(', ');
  };

  const handleAddressResultClick = (result: SearchResult) => {
    setValue('line1', result.address_line_1);
    setValue('line2', result.address_line_2);
    setValue('city', result.town);
    setValue('postcode', result.postcode);
    setValue('country', result.country);
    setManualLookUp(true);
  };

  return (
    <AddressLookupContainer>
      <LabelTextContainer>{translationsContent.bringItemsTo}</LabelTextContainer>
      <StyledInput
        value={term}
        onChange={(event: ChangeEvent<HTMLInputElement>) => setTerm(event.target.value)}
        placeholder={translationsContent.startTyping}
      />
      <ResultsContainer>
        {results.map((result: SearchResult) => {
          return (
            <AddressSelectButton key={JSON.stringify(result)} onClick={() => handleAddressResultClick(result)}>
              {parseSearchResultToString(result)}
            </AddressSelectButton>
          );
        })}
      </ResultsContainer>
    </AddressLookupContainer>
  );
};
