import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';

export const OrdersList = (): JSX.Element => {
  const { translationsContent, getTranslationWithValues } = useTranslations<translationsType>(translations);

  return (
    <div>
      A list of orders
      <div>{translationsContent.FOO}</div>
      <div>{getTranslationWithValues(translationsContent.BAR, ['3', '5'])}</div>
    </div>
  );
};
