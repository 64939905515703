import styled from 'styled-components';
import useMatchMedia from 'use-match-media-hook';
import { StyledParagraphLarge } from '../common/typography/Typography';
import {
  dateFormats,
  formatDate,
  isTodayOrTomorrow,
  parseDate,
  transformDateWithUtcOffset,
} from '../dates/dateFormatting';
import { breakPointLG } from '../globalConfig/styling/breakPoints';
import { GetOrderInfoTransformedResponse, JourneyTransformedResponse } from '../network/api/endpoints';
import { translationsType } from './translations';

const FadedParagraphLarge = styled(StyledParagraphLarge)`
  color: ${(props) => props.theme.fontColorFaded};
`;

interface InProgressInfoProps {
  deliveryInfo: Exclude<GetOrderInfoTransformedResponse['deliveryInfo'], null>;
  translations: translationsType;
  utcOffsetSeconds: number;
}
const InProgressInfo = ({ deliveryInfo, translations, utcOffsetSeconds }: InProgressInfoProps) => {
  const localisedDate = transformDateWithUtcOffset(deliveryInfo.bookingDate, utcOffsetSeconds);
  const todayOrTomorrow = isTodayOrTomorrow(localisedDate);
  const todayOrTomorrowText =
    todayOrTomorrow &&
    {
      today: translations.bookedForToday,
      tomorrow: translations.bookedForTomorrow,
    }[todayOrTomorrow];

  const queries = [`(min-width: ${breakPointLG}px)`];
  const [desktop] = useMatchMedia(queries);

  const bookedDate = formatDate(localisedDate, dateFormats.dateMonth);
  const bookedTime = `${formatDate(
    parseDate(deliveryInfo.bookingTimeslotStart, dateFormats.hourMinute),
    dateFormats.hourMinuteAmPm,
  )}-${formatDate(parseDate(deliveryInfo.bookingTimeslotEnd, dateFormats.hourMinute), dateFormats.hourMinuteAmPm)}`;

  if (desktop) {
    return (
      <>
        {todayOrTomorrow && <StyledParagraphLarge>{todayOrTomorrowText}</StyledParagraphLarge>}
        <FadedParagraphLarge>
          {bookedDate} | {bookedTime}
        </FadedParagraphLarge>
      </>
    );
  }
  return (
    <>
      {todayOrTomorrow && <StyledParagraphLarge>{todayOrTomorrowText}</StyledParagraphLarge>}
      <FadedParagraphLarge>{bookedDate}</FadedParagraphLarge>
      <FadedParagraphLarge>{bookedTime}</FadedParagraphLarge>
    </>
  );
};

interface DeliveredInfoProps {
  lastDeliveryJourney: JourneyTransformedResponse;
  translations: translationsType;
  utcOffsetSeconds: number;
}
const DeliveredInfo = ({ lastDeliveryJourney, translations, utcOffsetSeconds }: DeliveredInfoProps) => {
  const localisedEndDatetime = transformDateWithUtcOffset(lastDeliveryJourney.endDatetime, utcOffsetSeconds);

  return (
    <>
      <StyledParagraphLarge>{`${translations.delivered} ${formatDate(
        localisedEndDatetime,
        dateFormats.dateMonth,
      )}`}</StyledParagraphLarge>
      <FadedParagraphLarge>{formatDate(localisedEndDatetime, dateFormats.hourMinuteAmPm)}</FadedParagraphLarge>
    </>
  );
};

interface NotBookedInfoProps {
  translations: translationsType;
}
const NotBookedInfo = ({ translations }: NotBookedInfoProps) => {
  return <FadedParagraphLarge>{translations.notYetBooked}</FadedParagraphLarge>;
};

interface DeliveryDetailsProps {
  order: GetOrderInfoTransformedResponse;
  translations: translationsType;
  mostRecentDeliveryJourney?: JourneyTransformedResponse;
}

export const DeliveryDetails = ({
  order,
  translations,
  mostRecentDeliveryJourney,
}: DeliveryDetailsProps): JSX.Element | null => {
  if (!order) {
    return null;
  }

  if (mostRecentDeliveryJourney === undefined || !order.deliveryInfo) {
    return <NotBookedInfo translations={translations} />;
  }

  // TODO: also add a conditional for in 'scheduled' state before this - ie either no journeys, or all delivery journeys cancelled/pending
  if (!mostRecentDeliveryJourney.inTerminalState && mostRecentDeliveryJourney.status !== 'pending') {
    return (
      <InProgressInfo
        deliveryInfo={order.deliveryInfo}
        translations={translations}
        utcOffsetSeconds={order.utcOffsetSeconds}
      />
    );
  }

  return (
    <DeliveredInfo
      lastDeliveryJourney={mostRecentDeliveryJourney}
      translations={translations}
      utcOffsetSeconds={order.utcOffsetSeconds}
    />
  );
};
