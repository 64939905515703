import styled from 'styled-components';
import { StyledParagraphMedium } from '../common/typography/Typography';
import { breakPointLG } from '../globalConfig/styling/breakPoints';

export const GridWrapper = styled.div<{ rowGap?: number; collapseLeftCol?: boolean }>`
  display: grid;
  grid-template-columns: auto 10fr;
  row-gap: ${(props) => (props.rowGap ? props.rowGap : 3)}px;
`;

export const WhiteBlock = styled.div`
  background-color: white;
  width: 100%;
`;

export const StatusUpdate = styled(StyledParagraphMedium)<{ faded?: boolean }>`
  ${(props) => (props.faded ? `color: ${props.theme.fontColorFaded};` : '')}
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  border-radius: 1px;

  @media (min-width: ${breakPointLG}px) {
    padding: 10px 20px;
  }
`;
