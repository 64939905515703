import styled from 'styled-components';
import { breakPointLG } from '../globalConfig/styling/breakPoints';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import { StyledParagraph } from '../common/typography/Typography';
import emailIcon from '../assets/icons/email-icon.svg';

const OrderHelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;

  @media (min-width: ${breakPointLG}px) {
    padding: 0;
  }
`;

const ContactLinksWrapper = styled.div<{ centered?: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => (props.centered ? 'align-items: center;' : '')}
`;

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 20px;
`;

const IconContainer = styled.div`
  border: 1px solid ${(props) => props.theme.blackColor};
  background: ${(props) => props.theme.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 18px 15px 23px;

  @media (min-width: ${breakPointLG}px) {
    height: 35px;
    width: 35px;
    margin-right: 10px;
    border-radius: 50%;
    padding: 0;
  }
`;

const IconImage = styled.img`
  background: ${(props) => props.theme.whiteColor};
  object-fit: contain;
  width: 20px;
  height: 20px;
  margin-right: 8px;

  @media (min-width: ${breakPointLG}px) {
    margin-right: 0;
  }
`;

const StyledContactLink = styled.a`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  line-height: 21px;
  text-decoration: none;
  width: 100%;
`;

const OrderHelpTextWrapper = styled.div`
  padding-bottom: 20px;
`;

const DesktopText = styled.div`
  display: none;

  @media (min-width: ${breakPointLG}px) {
    display: block;
  }
`;

const MobileText = styled.div`
  display: block;
  background: transparent;

  @media (min-width: ${breakPointLG}px) {
    display: none;
  }
`;

interface ContactLinkProps {
  link: string;
  text: string;
  mobileText?: string;
  iconUrl?: string;
  alt: string;
}

const ContactLink = (props: ContactLinkProps): JSX.Element => {
  const { link, text, mobileText, iconUrl, alt } = props;

  return (
    <LinkContainer>
      <StyledContactLink href={link}>
        <IconContainer>
          <IconImage src={iconUrl} alt={alt} />
          <MobileText>{mobileText ? mobileText : text}</MobileText>
        </IconContainer>
        <DesktopText>{text}</DesktopText>
      </StyledContactLink>
    </LinkContainer>
  );
};

interface OrderHelpProps {
  centered?: boolean;
}

export const OrderHelp = ({ centered }: OrderHelpProps): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);

  return (
    <OrderHelpContainer>
      <OrderHelpTextWrapper>
        <StyledParagraph bold>{translationsContent.title}</StyledParagraph>
        <StyledParagraph>{translationsContent.getInTouch}</StyledParagraph>
      </OrderHelpTextWrapper>
      <ContactLinksWrapper centered={centered}>
        <ContactLink
          alt="email"
          iconUrl={emailIcon}
          link={`mailto:clientservice@toshi.co`}
          text={'clientservice@toshi.co'}
        />
      </ContactLinksWrapper>
    </OrderHelpContainer>
  );
};
