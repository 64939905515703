import styled from 'styled-components';
import { theme } from '../../globalConfig/styling/theme';
import { breakPointLG } from '../../globalConfig/styling/breakPoints';

export interface BoldProp {
  bold?: boolean;
}

interface TypographyProp extends BoldProp {
  invertColor?: boolean;
  uppercase?: boolean;
  center?: boolean;
}

export const StyledH1 = styled.h1`
  font-size: ${(props) => props.theme.fontSizeTitle};
  background: transparent;
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;
  line-height: 42px;
`;

export const StyledH3 = styled.h3<TypographyProp>`
  font-size: ${(props) => props.theme.fontSizeNormal};
  background: transparent;
  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : '')}
  color: ${(props) => (props.invertColor ? theme.invertColor : theme.color)};
  text-align: left;
  font-weight: 600;

  @media (min-width: ${breakPointLG}px) {
    font-size: ${(props) => props.theme.fontSizeSubTitle};
  }
`;

export const StyledParagraph = styled.p<TypographyProp>`
  font-size: ${(props) => props.theme.fontSizeNormal};
  background: transparent;
  color: ${(props) => (props.invertColor ? theme.invertColor : theme.color)};
  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : '')};
  ${(props) => (props.bold ? 'font-weight: 600' : '')};
  line-height: 21px;
  ${(props) => (props.center ? 'text-align: center;' : '')};
`;

export const SpanBold = styled.span`
  font-weight: 600;
  font-size: inherit;
`;

export const StyledParagraphMedium = styled.p<BoldProp>`
  font-size: ${(props) => props.theme.fontSizeLarge};
  background: transparent;
  line-height: 24px;
  ${(props) => (props.bold ? 'font-weight: 600' : '')};
`;

export const StyledParagraphLarge = styled.p<BoldProp>`
  font-size: ${(props) => props.theme.fontSizeSubTitle};
  background: transparent;
  line-height: 27px;
  font-weight: ${(props) => props.style?.fontWeight};
  ${(props) => (props.bold ? 'font-weight: 600' : '')};

  @media (min-width: ${breakPointLG}px) {
    font-size: ${(props) => props.theme.fontSizeTitle};
    line-height: 42px;
  }
`;

export const StyledParagraphSmall = styled.p`
  font-size: ${(props) => props.theme.fontSizeSmall};
  background: transparent;
`;
