import useLocalStorage from '@rehooks/local-storage';
import { createContext, ReactNode, useCallback, useContext } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-function
const defaultContextValue = { isAuthed: null, setIsAuthed: (_: boolean | null) => {} };

interface ContextValue {
  isAuthed: boolean | null;
  setIsAuthed: (isAuthed: boolean | null) => void;
}

const AuthContext = createContext<ContextValue>(defaultContextValue);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [value, setNewValue] = useLocalStorage<boolean | null>('isAuthed');
  const memoizedSetNewValue = useCallback((val) => setNewValue(val), [setNewValue]);
  return (
    <AuthContext.Provider value={{ isAuthed: value, setIsAuthed: memoizedSetNewValue }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useProfile = (): ContextValue => useContext(AuthContext);
