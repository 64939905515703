import styled from 'styled-components';
import { SpanBold, StyledH3, StyledParagraphMedium } from '../common/typography/Typography';
import { dateFormats, formatDate } from '../dates/dateFormatting';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import giftIcon from '../assets/icons/gift-icon.svg';
import { useLocation } from 'react-router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
`;

const GiftScheduledTitle = styled(StyledH3)`
  text-align: center;
`;

const GiftScheduledLine = styled.div`
  background-color: #f7f7f7;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const GiftIcon = styled.img`
  background-color: transparent;
  height: ${(props) => props.theme.fontSizeLarge};
  margin-right: 5px;
`;

export const OrderComplete = (): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);

  const history = useLocation<{
    bookingDate: string;
    bookingTimeslotStart: string;
    bookingTimeslotEnd: string;
    deliveryAddress: string;
  }>();
  const bookingDate = new Date(history.state.bookingDate);
  const bookingTimeslotStart = history.state.bookingTimeslotStart;
  const bookingTimeslotEnd = history.state.bookingTimeslotEnd;
  const deliveryAddress = history.state.deliveryAddress;

  return (
    <Wrapper>
      <GiftScheduledLine>
        <GiftIcon src={giftIcon} alt="Gift icon" />
        <GiftScheduledTitle>{translationsContent.giftScheduled}</GiftScheduledTitle>
        <GiftIcon src={giftIcon} alt="Gift icon" />
      </GiftScheduledLine>
      <StyledParagraphMedium>
        {translationsContent.itWillBeDeliveredTo} <SpanBold>{deliveryAddress}</SpanBold>
        {` ${translationsContent.On}`} <SpanBold>{`${formatDate(bookingDate, dateFormats.dateMonth)}`},</SpanBold>
        {` ${translationsContent.Between}`}{' '}
        <SpanBold>
          {bookingTimeslotStart} - {bookingTimeslotEnd}.
        </SpanBold>
      </StyledParagraphMedium>
    </Wrapper>
  );
};
