import { useState, useCallback } from 'react';
import { useDebouncedEffect } from '../helpers/hooks/useDebouncedEffect';
import { useMutateData } from '../network/api/api';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import {
  UseFormSetError,
  UseFormClearErrors,
  FieldValues,
  UseFormSetValue,
  useWatch,
  Control,
  UseFormRegister,
  FieldErrors,
} from 'react-hook-form';
import {
  PostAddressEligibleResp,
  addressAutocompleteEndpoint,
  AddressAutocompleteResponse,
  postAddressEligibleEndpoint,
  GetOrderInfoTransformedResponse,
} from '../network/api/endpoints';
import styled from 'styled-components';
import { DisabledFormField, FormField } from './FormField';
import { AddressFinder } from './AddressFinder';

const EnterManualLink = styled.button`
  display: flex;
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 8px;
`;

const LabelTextContainer = styled.div`
  padding-bottom: 8px;
`;

export interface SearchResult {
  address_line_1: string;
  address_line_2: string | null;
  town: string | null;
  province: string;
  postcode: string;
  country: string;
}

export interface AddressValuesState {
  line1: string;
  line2: string;
  city: string;
  postcode: string;
  country: string;
}

const ErrorContainer = styled.div`
  color: ${(props) => props.theme.errorColor};
`;

const Hidden = styled.div`
  display: none;
`;

interface AddressFieldsProps {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  control: Control;
  orderId: string;
  orderDetails: GetOrderInfoTransformedResponse;
}

export const AddressFields = ({
  register,
  errors,
  setError,
  clearErrors,
  setValue,
  control,
  orderId,
  orderDetails,
}: AddressFieldsProps): JSX.Element => {
  const [manualLookUp, setManualLookUp] = useState(false);
  const [term, setTerm] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const { mutate } = useMutateData();
  const { translationsContent } = useTranslations<translationsType>(translations);
  const postcodeWatch = useWatch({
    control,
    name: 'postcode',
  });

  const checkPostcodeEligibility = useCallback(async () => {
    if (postcodeWatch === '') {
      clearErrors('postcode');
      return;
    }

    const resp = await mutate<PostAddressEligibleResp>(
      postAddressEligibleEndpoint(orderId),
      { postcode: postcodeWatch, store_id: orderDetails.storeId, order_id: orderId },
      'POST',
    );

    if ('error' in resp) {
      setError('postcode', {
        type: 'invalid',
        message: translationsContent.serverError,
      });

      return;
    }

    if (!resp.data.eligible) {
      setError('postcode', {
        type: 'invalid',
        message:
          orderDetails.storeLocation === 'LDN'
            ? translationsContent.mustBeLondonZones
            : translationsContent.mustBeManhattan,
      });
    } else {
      clearErrors('postcode');
    }
  }, [
    postcodeWatch,
    clearErrors,
    mutate,
    orderId,
    setError,
    orderDetails.storeId,
    orderDetails.storeLocation,
    translationsContent,
  ]);

  const handleAddressSearch = useCallback(async () => {
    if (term === '') {
      clearErrors('search');
      setResults([]);
      return;
    }

    const response = await mutate<AddressAutocompleteResponse>(
      addressAutocompleteEndpoint,
      {
        query: term,
        store_id: orderDetails.storeId,
      },
      'POST',
    );

    if ('error' in response) {
      setError('search', {
        type: 'server',
        message: translationsContent.serverError,
      });
      return;
    }

    if ('data' in response) {
      const predictions = response.data.predictions.map((prediction) => {
        return prediction.address_components;
      });
      setResults(predictions);
    }
  }, [term, setError, clearErrors, translationsContent, mutate, orderDetails.storeId]);

  useDebouncedEffect(
    () => {
      checkPostcodeEligibility();
    },
    [postcodeWatch, checkPostcodeEligibility],
    1000,
  );

  useDebouncedEffect(
    () => {
      handleAddressSearch();
    },
    [term, handleAddressSearch],
    1000,
  );

  const RenderManualForm = () => {
    return (
      <>
        <LabelTextContainer>{translationsContent.bringItemsTo}</LabelTextContainer>
        <FormField
          formFieldName="line1"
          errorText={errors.line1}
          required
          register={register}
          labelText={translationsContent.line1}
        />
        <FormField
          formFieldName="line2"
          errorText={errors.line2}
          register={register}
          labelText={translationsContent.line2}
        />
        <FormField
          formFieldName="city"
          errorText={errors.city}
          required
          register={register}
          labelText={translationsContent.city}
        />
        <FormField
          formFieldName="postcode"
          required
          register={register}
          labelText={translationsContent.postcode}
          customError={
            <>
              {errors.postcode && errors.postcode.type == 'required' && (
                <ErrorContainer>{translationsContent.fieldIsRequired}</ErrorContainer>
              )}
              {errors.postcode && errors.postcode.type == 'invalid' && (
                <ErrorContainer>{errors.postcode.message}</ErrorContainer>
              )}
            </>
          }
        />
        <FormField
          isLastField
          formFieldName="country"
          errorText={errors.country}
          register={register}
          labelText={translationsContent.country}
        />
      </>
    );
  };

  const deliveryInfo = orderDetails.deliveryInfo;
  const unscheduledOrderAddress = orderDetails.unscheduledOrderAddress;
  const formattedUnscheduledOrderAddress = unscheduledOrderAddress
    ? [unscheduledOrderAddress.line1, unscheduledOrderAddress.cityTown, unscheduledOrderAddress.postcodeZipcode]
        .filter(Boolean)
        .join(', ')
    : null;
  const address = deliveryInfo !== null ? deliveryInfo.deliveryAddress : formattedUnscheduledOrderAddress;

  if (address === null) {
    return (
      <>
        {manualLookUp ? (
          RenderManualForm()
        ) : (
          <AddressFinder
            term={term}
            setTerm={setTerm}
            results={results}
            setValue={setValue}
            setManualLookUp={setManualLookUp}
          />
        )}

        <EnterManualLink onClick={() => setManualLookUp(!manualLookUp)} type="button">
          {manualLookUp ? translationsContent.orSearch : translationsContent.orManual}
        </EnterManualLink>
      </>
    );
  }

  return (
    <>
      <Hidden>
        <RenderManualForm />
      </Hidden>
      <DisabledFormField isLastField labelText={translationsContent.toshiAssistantDeliverItemsTo} value={address} />
    </>
  );
};
