import { isToday, isTomorrow, format, parse, add, parseISO } from 'date-fns';

// See https://date-fns.org/v2.25.0/docs/format
export const dateFormats = {
  dateMonth: 'do MMMM', // e.g. 1st October, 5th August
  longDateFormat: `yyyy-MM-dd'T'HH:mm:ss'.'SSS'Z'`, // e.g. 2021-05-02T14:23:13.012Z
  yearMonthDate: `yyyy-MM-dd`, // e.g. 2021-12-23
  hourMinute: `HH:mm`, // e.g. 23:05
  dateMonthYear: `do MMMM Y`, // e.g. 23rd November 2021
  hourMinuteAmPm: `h:mmaaa`, // e.g. 3:05pm, 11:12am
} as const;

export type DateFormat = typeof dateFormats[keyof typeof dateFormats];

export const formatDate = (date: Date, dateFormat: DateFormat): string => {
  return format(date, dateFormat);
};

export const parseDate = (date: string, dateFormat: DateFormat): Date => {
  return parse(date, dateFormat, new Date());
};

export const isTodayOrTomorrow = (date: Date | undefined): 'today' | 'tomorrow' | undefined => {
  if (date === undefined) {
    return undefined;
  }

  if (isToday(date)) {
    return 'today';
  }

  if (isTomorrow(date)) {
    return 'tomorrow';
  }

  return undefined;
};

export const transformDateWithUtcOffset = (date: Date | string, utcOffset: number | undefined): Date => {
  const dateObj = date instanceof Date ? date : parseISO(date);
  if (utcOffset === undefined) {
    return dateObj;
  }
  return add(dateObj, { seconds: utcOffset });
};
