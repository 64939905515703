import get from 'lodash.get';
import { useEffect, useState } from 'react';

type translationErrors = {
  default: string;
  translations: {
    missing: string;
  };
};

export interface TranslationsContent<Tln> {
  language: string;
  errors: translationErrors;
  translations: Tln;
}

export type translationsInput<Tln> = {
  [key: string]: TranslationsContent<Tln>;
};

/**
 *
 * @param translations A translations json file
 * @returns Translations map and two functions:
 *
 * translationsContent - Map of translation key-value pairs for the language of the given user
 *
 * getTranslationWithValues - Used for translating server responses
 *
 * getTranslationForDynamicKey - Used for translating text with variables in, e.g.
 * "SOME_KEY": "We like both {1} and {2} here at company {3}"
 */
export const useTranslations = <TlnType extends Record<string, string>>(
  translations: translationsInput<TlnType>,
): {
  translationsContent: TlnType;
  getTranslationWithValues: (translation: string, values: string[]) => string;
  getTranslationForDynamicKey: (translationKey?: string | null) => string;
} => {
  const detectedLocale = navigator.language ?? navigator.languages?.[0] ?? 'en-GB';

  const [locale] = useState<string>(detectedLocale);

  useEffect(() => {
    document.querySelector('html')?.setAttribute('lang', locale);
  }, [locale]);

  let translationsContent = translations[detectedLocale];
  if (translationsContent === undefined) {
    translationsContent = translations['en-GB'];
  }

  return {
    translationsContent: translationsContent.translations,
    getTranslationWithValues: (translation: string, values: string[]) => {
      let finalTranslation = translation;

      values.forEach((v, idx) => {
        finalTranslation = finalTranslation.split(`{${idx}}`).join(v);
      });

      return finalTranslation;
    },
    getTranslationForDynamicKey: (translationKey?: string | null): string => {
      return get(
        translationsContent.translations,
        translationKey as string,
        translationsContent.errors.translations.missing,
      );
    },
  };
};
