import styled from 'styled-components';
import { breakPointLG } from '../../globalConfig/styling/breakPoints';

export const StyledWrapper = styled.section`
  display: flex;
  background-color: ${(props) => props.theme.lighterGrey};
  flex-direction: column-reverse;

  @media (min-width: ${breakPointLG}px) {
    flex-direction: row;
  }
`;
