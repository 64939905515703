import styled from 'styled-components';
import toshiLogo from '../assets/icons/toshi-logo.svg';
import { breakPointLG, breakPoint2XL } from '../globalConfig/styling/breakPoints';

const NavbarContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${breakPointLG}px) {
    display: block;
    padding: 0 30px;
  }

  @media (min-width: ${breakPoint2XL}px) {
    padding: 0 70px;
  }
`;

const NavbarInnerContainer = styled.div`
  @media (min-width: ${breakPointLG}px) {
    border-bottom: 1px solid #ddd;
  }
`;

const ToshiLogo = styled.img`
  padding: 20px 0;
`;

export const NavBar = (): JSX.Element => {
  return (
    <NavbarContainer>
      <NavbarInnerContainer>
        <ToshiLogo src={toshiLogo} alt={'Toshi Logo'} />
      </NavbarInnerContainer>
    </NavbarContainer>
  );
};
