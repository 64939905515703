import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import tick from '../../assets/images/tick.svg';

interface CheckboxProps {
  checked: boolean;
  onClick: () => void;
  checkboxId: string;
  register?: UseFormRegister<FieldValues>;
  formFieldName?: string;
}

interface InnerCheckboxProps {
  checked: boolean;
}

const CheckboxButton = styled.div`
  height: 35px;
  width: 35px;
  border: 2px solid ${(props) => props.theme.blackColor};
  background-color: ${(props) => props.theme.whiteColor};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  margin-right: 10px;
  padding: 3px;
  user-select: none;

  // Prevent blue background on tap on webkit based browsers
  -webkit-tap-highlight-color: transparent;
`;

const InnerCheckbox = styled.div<InnerCheckboxProps>`
  background-color: ${(props) => (props.checked ? props.theme.blackColor : props.theme.whiteColor)};
  height: 100%;
  width: 100%;
  user-select: none;
`;

const StyledTick = styled.img`
  background-color: ${(props) => props.theme.blackColor};
  padding: 3px;
  object-fit: contain;
  height: 100%;
  width: 100%;
`;

const HiddenInput = styled.input`
  display: none;
`;

export const Checkbox = ({ checked, onClick, checkboxId, register, formFieldName }: CheckboxProps): JSX.Element => {
  const formRegister = register && formFieldName && register(formFieldName, { value: checked });

  return (
    <>
      <CheckboxButton aria-checked={checked} onKeyDown={onClick} tabIndex={0} role="checkbox" onClick={onClick}>
        <InnerCheckbox checked={checked}>{checked ? <StyledTick src={tick} alt={'tick'} /> : null}</InnerCheckbox>
      </CheckboxButton>
      <HiddenInput {...(formRegister && formRegister)} id={checkboxId} type="checkbox" defaultChecked={checked} />
    </>
  );
};
