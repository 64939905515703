import { createGlobalStyle } from 'styled-components';

export const globalStyle = createGlobalStyle`
  * {
    font-weight: 400;
    font-family: MarkOT;
    color: ${(props) => props.theme.color};
    font-size: ${(props) => props.theme.fontSizeNormal};
    background-color: ${(props) => props.theme.backgroundColor};
    outline: ${(props) => props.theme.outline};
    box-sizing: border-box;
  }

  body,
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  a {
    display: flex;
    background-color: transparent;
    text-decoration: underline;
  }
`;
