import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { Root } from './Root';
import { getEnvironment } from './bugsnag/getEnvironment';
import { StrictMode } from 'react';

if (getEnvironment() !== 'development' && process.env.NODE_ENV !== 'test') {
  // API key goes here as it would be visible even if we made it a secret due to us
  // reporting front end errors to Bugsnag anyway
  Bugsnag.start({
    apiKey: 'f723142e095b34f4742ae470d29153c8',
    plugins: [new BugsnagPluginReact()],
    collectUserIp: false,
    releaseStage: getEnvironment(),
  });
}

ReactDOM.render(
  <StrictMode>
    <Root />
  </StrictMode>,
  document.getElementById('root'),
);
Modal.setAppElement('#root');
