export const colorVars = {
  blackColor: '#000000',
  whiteColor: '#FFFFFF',
  lightGrey: '#E5E5E5',
  lighterGrey: '#F7F7F7',
  darkGrey: '#CCCCCC',
  redColor: 'red',
};

export const theme = {
  blackColor: colorVars.blackColor,
  whiteColor: colorVars.whiteColor,
  lightGrey: colorVars.lightGrey,
  lighterGrey: colorVars.lighterGrey,
  darkGrey: colorVars.darkGrey,
  errorColor: colorVars.redColor,
  fontFamily: 'MarkOT',
  color: colorVars.blackColor,
  invertColor: colorVars.whiteColor,
  fontColorFaded: '#999999',
  fontSizeSmall: '12px',
  fontSizeNormal: '15px',
  fontSizeLarge: '16px',
  fontSizeSubTitle: '21px',
  fontSizeTitle: '30px',
  backgroundColor: colorVars.lighterGrey,
  textBackgroundColor: colorVars.whiteColor,
  outline: 'none',
  boxShadow: '0px 4px 16px 4px rgba(0, 0, 0, 0.1)',
};

export type ThemeType = typeof theme;
