import { createGlobalStyle } from 'styled-components';
import ttf from './MarkOT-Light.ttf';

export const markOTLightFontFace = `@font-face {
  font-family: MarkOT;
  font-weight: 400;
  src: url('${ttf}')
}`;

export const globalFontStyle = createGlobalStyle`
  ${markOTLightFontFace}
`;
