import camelCase from 'lodash.camelcase';
import { PersonResponse } from '../api/endpoints';

export const transformPersonResponse = (personResponse: PersonResponse): string => {
  if ('title' in personResponse) {
    return `${personResponse.title} ${personResponse.last_name}`;
  }
  return `${personResponse.first_name} ${personResponse.last_name}`;
};

export const camelizeObject = (obj: Record<string, unknown> | unknown[]): Record<string, unknown> | unknown[] => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeObject(v as unknown[]));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeObject(obj[key] as Record<string, unknown>),
      }),
      {},
    );
  }
  return obj;
};

export const capitaliseFirstLetter = (input: string): string => {
  const [firstLetter, ...rest] = input;

  return `${firstLetter.toUpperCase()}${rest.join('')}`;
};
