import styled from 'styled-components';
import { breakPointLG, breakPoint2XL } from './breakPoints';

export const ColumnForty = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPointLG}px) {
    width: 40%;
    padding: 30px;
    align-items: center;
  }

  @media (min-width: ${breakPoint2XL}px) {
    padding: 48px 70px;
  }
`;

export const ColumnFifty = styled.section`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPointLG}px) {
    width: 50%;
    padding: 48px 70px;
  }
`;

export const ColumnSixty = styled.section`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPointLG}px) {
    width: 60%;
    padding: 30px;
    align-items: center;
  }

  @media (min-width: ${breakPoint2XL}px) {
    padding: 48px 70px;
  }
`;
