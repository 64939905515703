import styled from 'styled-components';
import { breakPointLG } from '../../globalConfig/styling/breakPoints';

export const MainCTA = styled.button`
  background: ${(props) => props.theme.color};
  border: none;
  color: ${(props) => props.theme.invertColor};
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 14px 0 16px;
  cursor: pointer;
  width: 100%;
`;

export const SecondaryCTA = styled.button`
  background-color: white;
  border: 1px solid black;
  text-transform: uppercase;
  cursor: pointer;
  width: 100%;
  padding: 15px 15px;

  &:hover {
    background-color: ${(props) => props.theme.lighterGrey};
  }

  @media (min-width: ${breakPointLG}px) {
    width: fit-content;
  }
`;
