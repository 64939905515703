import { useEffect, useReducer, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { MainCTA } from '../common/buttons/buttons';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { StyledSelect, Checkbox, StyledLabel } from '../common/formComponents/FormComponents';
import { AddressFields } from './AddressFields';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import { useMutateData } from '../network/api/api';
import {
  getAvailabilityEndpoint,
  GetAvailabilityParams,
  GetAvailabilityResponse,
  GetOrderInfoTransformedResponse,
} from '../network/api/endpoints';
import { addDays } from 'date-fns';
import { dateFormats, formatDate, parseDate, isTodayOrTomorrow } from '../dates/dateFormatting';
import { useHistory } from 'react-router';
import { StyledParagraphSmall } from '../common/typography/Typography';
import {
  transformAvailabilityInfo,
  GetAvailabilityTransformedDates,
} from '../network/transformers/transformAvailabilityInfo';
import { Services } from './Services';
import { breakPointLG } from '../globalConfig/styling/breakPoints';

const FormFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormItemContainer = styled.div`
  padding-top: 20px;
  width: 100%;
`;

const SmallerFormItemContainer = styled(FormItemContainer)`
  @media (min-width: ${breakPointLG}px) {
    width: 300px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;
`;

const LabelTextContainer = styled.div`
  padding-bottom: 8px;
`;

const ErrorContainer = styled.div`
  color: ${(props) => props.theme.errorColor};
`;

const SubmitActionContainer = styled(FormItemContainer)`
  display: flex;
  justify-content: center;
`;

const TermsLink = styled.a`
  font-size: ${(props) => props.theme.fontSizeSmall};
  text-decoration: underline;
  display: inline;
`;

type GetAvailabilityTransformedData = {
  available: boolean;
  orderHoldMinutes: number;
  dates: GetAvailabilityTransformedDates;
};

const GreyedSelect = styled(StyledSelect)`
  background-color: ${(props) => props.theme.lightGrey};
`;

export type servicesReducerAction = {
  service: keyof GetOrderInfoTransformedResponse['services'];
  enabled: boolean;
  text?: string;
};
const servicesReducer = (
  state: GetOrderInfoTransformedResponse['services'],
  action: servicesReducerAction,
): GetOrderInfoTransformedResponse['services'] => {
  const newState = {
    ...state,
    [action.service]: {
      enabled: action.enabled,
    },
  };

  if (action.service === 'inspireMe') {
    newState.inspireMe.text = action.text;
  }
  return newState;
};

interface SubmitFormData extends Record<string, unknown> {
  [key: string]: unknown;
  receive_updates: boolean;
  delivery_date: string;
  delivery_slot_id: string;
}

interface ScheduleOrderFormProps {
  orderDetails: GetOrderInfoTransformedResponse;
  storeId: number;
  orderId: string;
  onSubmit: (data: SubmitFormData) => Promise<void>;
  submitError: string | boolean;
  availabilityData: GetAvailabilityTransformedData | undefined;
  setAvailabilityData: (data: GetAvailabilityTransformedData) => void;
}
export const ScheduleOrderForm = ({
  orderDetails,
  orderId,
  storeId,
  onSubmit,
  submitError,
  availabilityData,
  setAvailabilityData,
}: ScheduleOrderFormProps): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);

  const [dates, setDates] = useState<Date[]>([]);
  const availabilityDataPresent = availabilityData?.dates && Object.keys(availabilityData.dates).length > 0;

  const { register, handleSubmit, setError, clearErrors, control, formState, getValues, setValue } = useForm();
  const errors = formState.errors;
  const [receiveUpdatesChecked, setReceiveUpdatesChecked] = useState(false);
  const history = useHistory();
  const { mutate } = useMutateData();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const postcodeWatch = useWatch({
    control,
    name: 'postcode',
  });

  const selectedDate = useWatch({
    control,
    name: 'delivery_date',
  });
  const [deliveryDateChanged, setDeliveryDateChanged] = useState(false);
  const slotsPresent: boolean = availabilityDataPresent && selectedDate && availabilityData?.dates[selectedDate];

  const [servicesState, dispatch] = useReducer(servicesReducer, orderDetails.services);

  const submitForm = async (data: SubmitFormData) => {
    setIsSubmitting(true);
    await onSubmit({
      ...data,
      services: servicesState,
    }).finally(() => setIsSubmitting(false));
  };

  const renderDateOptions = (dates: Date[]) => {
    if (!dates || dates.length === 0 || !availabilityData) {
      return <option value="">{translationsContent.pleaseEnterAnAddress}</option>;
    }

    const emptyDates = Object.keys(availabilityData.dates).filter((date) => availabilityData.dates[date] === null);

    return dates
      .filter((date) => !emptyDates.includes(formatDate(date, dateFormats.yearMonthDate)))
      .map((date: Date) => {
        const todayOrTomorrow = isTodayOrTomorrow(date);
        const todayOrTomorrowText =
          todayOrTomorrow &&
          {
            today: ` (${translationsContent.today})`,
            tomorrow: ` (${translationsContent.tomorrow})`,
          }[todayOrTomorrow];
        return (
          <option value={formatDate(date, dateFormats.yearMonthDate)} key={date.toString()}>
            {formatDate(date, dateFormats.dateMonthYear)}
            {todayOrTomorrowText && todayOrTomorrowText}
          </option>
        );
      });
  };

  useEffect(() => {
    if (orderDetails.deliveryInfo !== null && orderDetails?.journeys?.length) {
      const lastJourneyToAddress = orderDetails.journeys[orderDetails.journeys.length - 1]?.endAddress;
      setValue('line1', lastJourneyToAddress.line1);
      setValue('line2', lastJourneyToAddress.line2);
      setValue('town', lastJourneyToAddress.cityTown);
      setValue('city', lastJourneyToAddress.cityTown);
      setValue('postcode', lastJourneyToAddress.postcodeZipcode);
    } else if (orderDetails.unscheduledOrderAddress) {
      const unscheduledAddress = orderDetails.unscheduledOrderAddress;
      setValue('line1', unscheduledAddress.line1);
      setValue('line2', unscheduledAddress.line2);
      setValue('town', unscheduledAddress.cityTown);
      setValue('city', unscheduledAddress.cityTown);
      setValue('postcode', unscheduledAddress.postcodeZipcode);
    }
  }, [orderDetails, setValue]);

  useEffect(() => {
    const getAvailability = async () => {
      if (errors.postcode || !postcodeWatch) {
        return;
      }

      const dateToday = new Date();

      if (!deliveryDateChanged && selectedDate !== undefined) {
        return;
      }

      setDeliveryDateChanged(false);

      let slotsForDates: string[] = [
        formatDate(dateToday, dateFormats.yearMonthDate),
        formatDate(addDays(dateToday, 1), dateFormats.yearMonthDate),
        formatDate(addDays(dateToday, 2), dateFormats.yearMonthDate),
      ];
      if (selectedDate && availabilityData) {
        const selectedDateObject = parseDate(selectedDate, dateFormats.yearMonthDate);
        slotsForDates = [
          formatDate(addDays(selectedDateObject, -1), dateFormats.yearMonthDate),
          formatDate(selectedDateObject, dateFormats.yearMonthDate),
          formatDate(addDays(selectedDateObject, 1), dateFormats.yearMonthDate),
        ];
      }

      const res = await mutate<GetAvailabilityTransformedData, GetAvailabilityResponse>(
        getAvailabilityEndpoint,
        {
          address_line_1: getValues('line1'),
          address_line_2: getValues('line2'),
          town: getValues('city'),
          postcode: getValues('postcode'),
          city: getValues('city'),
          store_id: storeId,
          interface: orderDetails.orderInterface,
          slots_for_dates: slotsForDates,
        } as GetAvailabilityParams,
        'POST',
        (response) => transformAvailabilityInfo(response),
        (err) => {
          if (err.status < 200 || err.status >= 400) {
            history.push('/gifting/error');
          }
        },
      );
      if ('data' in res) {
        let newAvailabilityData = res.data;
        if (availabilityData) {
          newAvailabilityData = {
            ...availabilityData,
            dates: {
              ...availabilityData?.dates,
              ...res.data.dates,
            },
          };
        }

        // if no delivery slots in date and one of the slots for dates and nothing comes back, set to null
        Object.keys(newAvailabilityData.dates).forEach((date) => {
          // No data and it's one of the days we asked for
          if (newAvailabilityData.dates[date]?.length === 0 && slotsForDates.indexOf(date) > -1) {
            newAvailabilityData.dates[date] = null;
          }
        });
        setAvailabilityData(newAvailabilityData);

        const mappedDates = Object.keys(newAvailabilityData.dates).map((date) =>
          parseDate(date, dateFormats.yearMonthDate),
        );
        setDates(mappedDates);
        if (!selectedDate) {
          setValue('delivery_date', formatDate(mappedDates[0], dateFormats.yearMonthDate));
          const firstDateDeliverySlots = newAvailabilityData.dates[Object.keys(newAvailabilityData.dates)[0]];
          setValue('delivery_slot_id', `${firstDateDeliverySlots?.[0].deliverySlotId}`);
        }
      }
    };

    getAvailability();
  }, [
    history,
    mutate,
    errors.postcode,
    getValues,
    postcodeWatch,
    storeId,
    setAvailabilityData,
    setValue,
    orderDetails.orderInterface,
    selectedDate,
    availabilityData,
    deliveryDateChanged,
  ]);

  const DateSelectComponent = availabilityDataPresent ? StyledSelect : GreyedSelect;
  const SlotSelectComponent = selectedDate ? StyledSelect : GreyedSelect;

  const orderScheduled = Boolean(orderDetails.deliveryInfo);

  return (
    <div>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormFieldsContainer>
          <FormItemContainer>
            <AddressFields
              control={control}
              setValue={setValue}
              errors={errors}
              register={register}
              setError={setError}
              clearErrors={clearErrors}
              orderId={orderId}
              orderDetails={orderDetails}
            />
          </FormItemContainer>

          <SmallerFormItemContainer>
            <StyledLabel htmlFor={'delivery_date'}>
              <LabelTextContainer>{translationsContent.arriveOn}</LabelTextContainer>
              <DateSelectComponent
                disabled={!availabilityDataPresent}
                id={'delivery_date'}
                {...register('delivery_date', {
                  required: true,
                  onChange: () => setDeliveryDateChanged(true),
                })}
              >
                {renderDateOptions(dates)}
              </DateSelectComponent>
            </StyledLabel>
          </SmallerFormItemContainer>

          <SmallerFormItemContainer>
            <StyledLabel htmlFor={'delivery_slot_id'}>
              <LabelTextContainer>{translationsContent.between}</LabelTextContainer>
              <SlotSelectComponent
                disabled={!selectedDate}
                id={'delivery_slot_id'}
                {...register('delivery_slot_id', { required: true })}
              >
                {!selectedDate && <option value="">{translationsContent.pleaseEnterAddressSelectDate}</option>}
                {slotsPresent &&
                  availabilityData?.dates[selectedDate]?.map((value) => {
                    return (
                      <option value={value.deliverySlotId} key={value.deliverySlotId}>
                        {formatDate(parseDate(value.startTime, dateFormats.longDateFormat), dateFormats.hourMinuteAmPm)}{' '}
                        - {formatDate(parseDate(value.endTime, dateFormats.longDateFormat), dateFormats.hourMinuteAmPm)}
                      </option>
                    );
                  })}
                {!slotsPresent && <option disabled>{translationsContent.noSlotsAvailableForDate}</option>}
              </SlotSelectComponent>
            </StyledLabel>
          </SmallerFormItemContainer>
          <FormItemContainer>
            <Services
              orderScheduled={orderScheduled}
              servicesState={servicesState}
              updateServiceState={dispatch}
              isGiftedOrder={Boolean(orderDetails.giftReceiver)}
            />
          </FormItemContainer>
        </FormFieldsContainer>
        <FormItemContainer>
          <StyledLabel htmlFor={'receive_updates'} id={'ftime'}>
            <CheckboxContainer>
              <Checkbox
                register={register}
                checkboxId={'receive_updates'}
                formFieldName={'receive_updates'}
                checked={receiveUpdatesChecked}
                onClick={() => setReceiveUpdatesChecked(!receiveUpdatesChecked)}
              />
              {translationsContent.toshiUpdates}*
            </CheckboxContainer>
          </StyledLabel>
        </FormItemContainer>
        <FormItemContainer>
          <StyledParagraphSmall>*{translationsContent.consentToToshiContact}</StyledParagraphSmall>
          <TermsLink target="_blank" href="https://www.toshi.co/termsconditions">
            {translationsContent.viewTermsAndConditions}
          </TermsLink>
        </FormItemContainer>
        <SmallerFormItemContainer>
          {isSubmitting && (
            <SubmitActionContainer>
              <LoadingSpinner />
            </SubmitActionContainer>
          )}
          {!isSubmitting && (
            <FormItemContainer>
              {errors.exampleRequired && <span>{translationsContent.fieldIsRequired}</span>}
              <MainCTA type="submit">{translationsContent.cta}</MainCTA>
              {submitError && (
                <ErrorContainer>
                  {typeof submitError === 'string' ? submitError : translationsContent.errorSubmittingGiftOrder}
                </ErrorContainer>
              )}
            </FormItemContainer>
          )}
        </SmallerFormItemContainer>
      </form>
    </div>
  );
};
