import { LocationMarkerIcon as HeroIconLocationMarker } from '@heroicons/react/outline';
import styled from 'styled-components';
import { StyledParagraphMedium } from '../common/typography/Typography';
import { AddressTransformedResponse } from '../network/api/endpoints';
import { capitaliseFirstLetter } from '../network/transformers/standardTransformers';
import { GridWrapper, StatusUpdate } from './SharedStyledComponents';
import { translationsType } from './translations';

const LocationMarkerIcon = styled(HeroIconLocationMarker)`
  align-self: center;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;

interface DeliveredToInfoProps {
  translations: translationsType;
  deliveryAddress: AddressTransformedResponse;
  orderDelivered: boolean;
}

export const DeliveryInfo = ({ translations, deliveryAddress, orderDelivered }: DeliveredToInfoProps): JSX.Element => {
  const title = orderDelivered ? translations.deliveredTo : translations.deliveringTo;
  return (
    <GridWrapper>
      <LocationMarkerIcon aria-label="Location marker icon" />
      <StyledParagraphMedium>{title}</StyledParagraphMedium>
      <div />
      <StatusUpdate>{capitaliseFirstLetter(deliveryAddress.line1)}</StatusUpdate>
      <div />
      <StatusUpdate>{capitaliseFirstLetter(deliveryAddress.cityTown)}</StatusUpdate>
      <div />
      <StatusUpdate>{deliveryAddress.postcodeZipcode.toUpperCase()}</StatusUpdate>
    </GridWrapper>
  );
};
