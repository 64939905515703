import { useCallback, useRef } from 'react';
import { useHistory } from 'react-router';
import useSWR, { SWRResponse, useSWRConfig } from 'swr';

export const useGetData = <
  Data = Record<string, unknown>,
  ResponseData = Record<string, unknown>,
  Error = Record<string, unknown>,
>(
  key: string,
  transformData?: (value: ResponseData) => Data | Promise<Data>,
  onError?: (err: Response) => void,
): SWRAbortResponse<Data, Error> => {
  return useSWRAbort<Data, ResponseData, Error>(key, transformData, onError);
};

type useMutateDataResult<ResponseType> = Promise<{ data: ResponseType } | { error: string }>;

type DefaultResponseType = Record<string, unknown> | string;
export const useMutateData = (): {
  mutate: <TransformedResponseType = DefaultResponseType, ResponseType = DefaultResponseType>(
    apiPath: string,
    body: Record<string, unknown>,
    httpMethod: 'POST' | 'PUT' | 'DELETE',
    transformDataMemoized?: (data: ResponseType) => TransformedResponseType,
    onErrorMemoized?: (err: Response) => void,
  ) => useMutateDataResult<TransformedResponseType>;
} => {
  const { mutate: swrMutate } = useSWRConfig();
  const history = useHistory();

  const mutate = useCallback(
    <TransformedResponseType = DefaultResponseType, ResponseType = DefaultResponseType>(
      apiPath: string,
      body: Record<string, unknown>,
      httpMethod: 'POST' | 'PUT' | 'DELETE',
      transformDataMemoized?: (data: ResponseType) => TransformedResponseType,
      onErrorMemoized?: (err: Response) => void,
    ) =>
      fetch(`${getBaseUrl()}/${apiPath}`, {
        method: httpMethod,
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(body),
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
        },
      })
        .then(async (resp) => {
          if (resp.status === 401) {
            history.push('/unauthorized');
            return { error: resp.statusText };
          }

          const json = await resp.json();
          if (resp.status < 200 || resp.status >= 400) {
            throw json.error;
          }
          await swrMutate(apiPath);
          if (transformDataMemoized) {
            return {
              data: transformDataMemoized(json),
            };
          }
          return { data: json };
        })
        .catch((rejectReason) => {
          if (onErrorMemoized) {
            onErrorMemoized(rejectReason);
          }
          return { error: rejectReason };
        }),
    [swrMutate, history],
  );

  return { mutate };
};

export type SWRAbortResponse<Data, Error> = SWRResponse<Data, Error> & {
  abort: () => void;
};

const fetcher = async <Data>(apiPath: string, signal: AbortSignal): Promise<Data> => {
  const resp = await fetch(`${getBaseUrl()}/${apiPath}`, { signal, credentials: 'include' });

  if (!resp.ok) {
    throw resp;
  }
  return resp.json();
};

const useSWRAbort = <
  Data = Record<string, unknown>,
  ResponseData = Record<string, unknown>,
  SWRError = Response | Error,
>(
  key: string,
  transformData?: (value: ResponseData) => Data | Promise<Data>,
  onError?: (err: Response) => void,
): SWRAbortResponse<Data, SWRError> => {
  const aborter = useRef<AbortController>();
  const abort = () => aborter.current?.abort();
  const history = useHistory();

  const res = useSWR<Data, SWRError>(
    key,
    (url) => {
      aborter.current = new AbortController();
      if (transformData) {
        return fetcher(url, aborter.current.signal).then((value) => transformData(value as ResponseData));
      }
      return fetcher(url, aborter.current.signal);
    },
    {
      onError: (err) => {
        // Send these to a backend error log service
        if (err instanceof Error) {
          console.log(err.message);
        }
        if (err instanceof Response) {
          if (onError) {
            onError(err);
          }
          if (err.status === 401) {
            history.push('/unauthorized', {
              path: history.location.pathname,
            });
          }
        }
        return err;
      },
      shouldRetryOnError: false,
      revalidateOnFocus: false,
    },
  );

  return { ...res, abort };
};

const getBaseUrl = () => {
  switch (window.location.host) {
    case 'next.my.toshi.co':
      return 'https://next.api.toshi.co';
    case 'staging.my.toshi.co':
      return 'https://staging.api.toshi.co';
    case 'my.toshi.co':
      return 'https://api.toshi.co';
    case 'my.toshi.test:8081':
      return 'https://api.toshi.test';
    default:
      return 'https://api.toshi.test';
  }
};
