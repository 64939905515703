import { useTranslations } from '../../localisation/translateText';
import { GetOrderInfoTransformedResponse } from '../../network/api/endpoints';
import { WhiteBlock } from '../../orderDetails/SharedStyledComponents';
import { translations, translationsType } from '../translations';
import itemImage from '../../assets/images/hands-icon-black.svg';
import { StyledParagraphMedium } from '../typography/Typography';
import styled from 'styled-components';

const YourOrder = styled(StyledParagraphMedium)`
  padding: 7px 20px;
  font-weight: bold;
`;

const QuantityText = styled(StyledParagraphMedium)`
  text-align: center;
  flex: 1;
`;

const OrderItem = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
  font-size: ${(props) => props.theme.fontSizeLarge};
  line-height: 24px;

  & > :nth-child(1) {
    flex: 0.5;
  }

  & > :nth-child(2) {
    flex: 1;
  }

  & > :nth-child(3) {
    flex: 4;
  }
`;

const BackupImage = styled.img`
  opacity: 0.2;
  flex: 1;
  padding: 5px;
`;

const RealImage = styled.img`
  flex: 1;
  max-width: 10vw;
`;

const NameSize = styled(WhiteBlock)`
  flex: 5;
`;

interface OrderItemsProps {
  orderData: GetOrderInfoTransformedResponse;
}
export const OrderItems = ({ orderData }: OrderItemsProps): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);

  return (
    <>
      <WhiteBlock>
        <YourOrder>{translationsContent.yourOrder}</YourOrder>
      </WhiteBlock>
      {orderData.orderItems.map((orderItem) => {
        return (
          <WhiteBlock key={orderItem.id}>
            <OrderItem>
              {orderItem.imageUrl && <RealImage src={orderItem.imageUrl} alt={translationsContent.orderItem} />}
              {!orderItem.imageUrl && <BackupImage src={itemImage} alt={translationsContent.orderItem} />}
              <WhiteBlock style={{ display: 'flex', gap: 5, paddingRight: 5 }}>
                <QuantityText>{orderItem.quantity}x</QuantityText>
                <NameSize>
                  <StyledParagraphMedium bold>{orderItem.name}</StyledParagraphMedium>
                  <StyledParagraphMedium>Size {orderItem.size}</StyledParagraphMedium>
                </NameSize>
              </WhiteBlock>
            </OrderItem>
          </WhiteBlock>
        );
      })}
    </>
  );
};
