import { useCallback, useState } from 'react';

interface UseLocalStorage {
  storedValue: string | null;
  setValue: (val: unknown) => void;
  removeValue: () => void;
}
export const useLocalStorage = (key: unknown, initialValue: unknown): UseLocalStorage => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(`${key}`);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that
  // persists the new value to localStorage.
  const setValue = useCallback(
    (value: unknown) => {
      try {
        setStoredValue(value);
        window.localStorage.setItem(`${key}`, JSON.stringify(value));
      } catch (error) {
        console.log(error);
      }
    },
    [key],
  );

  const removeValue = useCallback(() => {
    try {
      setStoredValue(undefined);
      window.localStorage.removeItem(`${key}`);
    } catch (error) {
      console.log(error);
    }
  }, [key]);

  return { storedValue, setValue, removeValue };
};
