import styled from 'styled-components';
import itemImage from '../assets/images/hands-icon-black.svg';
import { StyledH3 } from '../common/typography/Typography';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import { breakPointLG, breakPoint2XL } from '../globalConfig/styling/breakPoints';
import { OrderItem } from '../network/api/endpoints';

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.boxShadow};
`;

const ItemContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${(props) => props.theme.darkGrey};
  padding-right: 20px;
  background: ${(props) => props.theme.whiteColor};

  @media (min-width: ${breakPointLG}px) {
    border-bottom: 0;
    border-top: 1px solid ${(props) => props.theme.darkGrey};
  }
`;

const QuantityBox = styled.div`
  padding: 20px;
  font-size: 15px;
  background-color: ${(props) => props.theme.textBackgroundColor};

  @media (min-width: ${breakPointLG}px) {
    padding: 20px 20px 0 20px;
    font-size: 21px;
  }

  @media (min-width: ${breakPoint2XL}px) {
    padding: 30px 30px 0 30px;
  }
`;

const TitleWrapper = styled.div`
  padding: 20px;
  background-color: ${(props) => props.theme.textBackgroundColor};
  width: 100%;

  @media (min-width: ${breakPointLG}px) {
    padding-top: 20px;
  }

  @media (min-width: ${breakPoint2XL}px) {
    padding-top: 30px;
  }
`;

const Blurb = styled.p`
  background-color: ${(props) => props.theme.textBackgroundColor};
`;

const PlaceholderWrapper = styled.div`
  display: none;

  @media (min-width: ${breakPointLG}px) {
    height: 100%;
    width: 108px;
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.lighterGrey};
  }
`;

const PlaceholderImage = styled.img`
  height: 44.5px;
  width: 49px;
  background-color: ${(props) => props.theme.lighterGrey};
  object-fit: contain;
  opacity: 0.2;
`;

interface ItemListProps {
  items: OrderItem[];
}

export const ItemList = ({ items }: ItemListProps): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);
  return (
    <ItemsContainer>
      {items.map((item: OrderItem, index: number) => {
        return (
          <ItemContainer key={index}>
            <PlaceholderWrapper>
              <PlaceholderImage alt={'Gifted item placeholder'} src={itemImage} />
            </PlaceholderWrapper>
            <QuantityBox>{item.quantity}</QuantityBox>
            <TitleWrapper>
              <StyledH3>{translationsContent.giftItemTitle}</StyledH3>
              <Blurb>{translationsContent.giftItemDescription}</Blurb>
            </TitleWrapper>
          </ItemContainer>
        );
      })}
    </ItemsContainer>
  );
};
