import styled from 'styled-components';

const StyledLoadingSpinner = styled.div`
  &,
  &::after {
    border-radius: 50%;
    width: 8em;
    height: 8em;
  }

  background-color: transparent;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 8px solid white;
  border-right: 8px solid white;
  border-bottom: 9px solid white;
  border-left: 8px solid black;
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;

  @keyframes load8 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingSpinner = (): JSX.Element => <StyledLoadingSpinner aria-label="Loading spinner" />;
