import { FieldValues, UseFormRegister } from 'react-hook-form';
import styled from 'styled-components';
import { StyledInput, StyledLabel } from '../common/formComponents/FormComponents';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations';

interface FormItemContainerProps {
  paddingOff?: boolean;
}

const FormItemContainer = styled.div<FormItemContainerProps>`
  padding-bottom: ${(props) => (props.paddingOff ? '0px' : '20px')};
  width: 100%;
`;

const LabelTextContainer = styled.div`
  padding-bottom: 8px;
`;

const ErrorContainer = styled.div`
  color: ${(props) => props.theme.errorColor};
`;

interface FormFieldProps {
  labelText: string;
  formFieldName: string;
  required?: boolean;
  errorText?: string;
  register: UseFormRegister<FieldValues>;
  isLastField?: boolean;
  customError?: JSX.Element;
}

const FieldRequiredError = () => {
  const { translationsContent } = useTranslations<translationsType>(translations);
  return <ErrorContainer>{translationsContent.fieldIsRequired}</ErrorContainer>;
};

export const FormField = ({
  labelText,
  formFieldName,
  required,
  errorText,
  register,
  isLastField,
  customError,
}: FormFieldProps): JSX.Element => {
  return (
    <FormItemContainer paddingOff={isLastField}>
      <StyledLabel>
        <LabelTextContainer>{labelText}</LabelTextContainer>
        <StyledInput id={formFieldName} {...register(formFieldName, { required: Boolean(required) })} />
        {!customError && errorText && <FieldRequiredError />}
        {customError && customError}
      </StyledLabel>
    </FormItemContainer>
  );
};

interface DisabledFormFieldProps {
  labelText: string;
  isLastField?: boolean;
  value: React.InputHTMLAttributes<HTMLInputElement>['value'];
}
export const DisabledFormField = ({ labelText, isLastField, value }: DisabledFormFieldProps): JSX.Element => {
  return (
    <FormItemContainer paddingOff={isLastField}>
      <StyledLabel>
        <LabelTextContainer>{labelText}</LabelTextContainer>
        <StyledInput disabled value={value} />
      </StyledLabel>
    </FormItemContainer>
  );
};
