import { createGlobalStyle } from 'styled-components';
import woff2 from './450fdebcd9b47d4e245c0272405e0cf2.woff2';
import woff from './450fdebcd9b47d4e245c0272405e0cf2.woff';
import ttf from './450fdebcd9b47d4e245c0272405e0cf2.ttf';

export const markOTMediumFontFace = `@font-face {
  font-family: MarkOT;
  font-weight: 600;
  src: url("${woff2}") format("woff2"),
  url("${woff}") format("woff"),
  url("${ttf}") format("truetype");
}
@font-face {
  font-family: MarkOT;
  font-weight: bold;
  src: url("${woff2}") format("woff2"),
  url("${woff}") format("woff"),
  url("${ttf}") format("truetype");
}
`;

export const globalFontStyle = createGlobalStyle`
  ${markOTMediumFontFace}
`;
