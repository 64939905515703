import styled from 'styled-components';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import { breakPointLG } from '../globalConfig/styling/breakPoints';
import { StyledH3, StyledParagraph } from '../common/typography/Typography';

const ServiceContainer = styled.div`
  padding: 30px;
  background-color: ${(props) => props.theme.whiteColor};
  box-shadow: ${(props) => props.theme.boxShadow};
  border-bottom: 1px solid ${(props) => props.theme.darkGrey};

  @media (min-width: ${breakPointLG}px) {
    border-bottom: 0;
  }
`;

const HeadingWrapper = styled.div`
  background-color: ${(props) => props.theme.whiteColor};
`;

export const Service = (): JSX.Element => {
  const { translationsContent } = useTranslations<translationsType>(translations);
  return (
    <ServiceContainer>
      <HeadingWrapper>
        <StyledH3>{translationsContent.dropOffTitle}</StyledH3>
      </HeadingWrapper>
      <StyledParagraph>{translationsContent.dropOffBlurb}</StyledParagraph>
    </ServiceContainer>
  );
};
