import { createGlobalStyle } from 'styled-components';
import { markOTHeavyFontFace } from './MarkOT-Heavy/@font-face/font-face-markot-heavy';
import { markOTMediumFontFace } from './MarkOT-Medium/@font-face/font-face-markot-medium';
import { markOTLightFontFace } from './MarkOT-Light/@font-face/font-face-markot-light';

export const globalFontStyle = createGlobalStyle`
  ${markOTLightFontFace}
  ${markOTMediumFontFace}
  ${markOTHeavyFontFace}
`;
