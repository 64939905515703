import { ComponentProps, useEffect, useState } from 'react';
import { Route } from 'react-router';
import Bugsnag from '@bugsnag/js';
import { Unauthorized } from '../errorPages/Unauthorized';
import { useTranslations } from '../localisation/translateText';
import { useMutateData } from '../network/api/api';
import { authEndpoint, AuthResponseType } from '../network/api/endpoints';
import { useQueryParams } from '../network/useQueryParams';
import { translations, translationsType } from './translations';
import { useLocalStorage } from './useLocalStorage';

export const AuthedRoute = ({ children, ...rest }: ComponentProps<typeof Route>): JSX.Element => {
  const queryParams = useQueryParams();
  const code = queryParams.get('code');
  const id = queryParams.get('id');
  const { mutate } = useMutateData();
  const { storedValue: isAuthed, setValue: setIsAuthed } = useLocalStorage('isAuthed', null);
  const [waitingForAuth, setWaitingForAuth] = useState(false);

  const { translationsContent } = useTranslations<translationsType>(translations);

  useEffect(() => {
    const authorise = async () => {
      if (isAuthed === null && (code === null || id === null)) {
        setIsAuthed(false);
      }

      if (isAuthed || code === null || id === null) {
        return;
      }

      setWaitingForAuth(true);
      const resp = await mutate<AuthResponseType>(
        authEndpoint,
        {
          code: code,
          id: id,
        },
        'POST',
      );
      const authResponse = 'data' in resp;
      if (authResponse) {
        Bugsnag.setUser(code, id);
      }
      setIsAuthed(authResponse);
      setWaitingForAuth(false);
    };

    authorise();
  }, [setIsAuthed, mutate, code, id, isAuthed]);

  if (waitingForAuth || isAuthed === null) {
    return <div>{translationsContent.waitingForAuth}</div>;
  }

  if (!isAuthed) {
    return <Unauthorized />;
  }

  return <Route {...rest}>{children}</Route>;
};
