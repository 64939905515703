import styled from 'styled-components';
import { Checkbox } from '../common/formComponents/Checkbox';
import { StyledParagraphMedium } from '../common/typography/Typography';
import { useTranslations } from '../localisation/translateText';
import { GetOrderInfoTransformedResponse } from '../network/api/endpoints';
import { servicesReducerAction } from './ScheduleOrderForm';
import { translations, translationsType } from './translations';

const ServiceWrapper = styled.div<{ isLastItem?: boolean }>`
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: ${(props) => `1px solid ${props.theme.darkGrey}`};
  background-color: ${(props) => props.theme.whiteColor};
  margin-bottom: 5px;
  ${(props) => (props.isLastItem ? 'margin-bottom: 0;' : '')}

  > * {
    background-color: ${(props) => props.theme.whiteColor};
  }
`;
const ServiceHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;
const FadedParagraphMedium = styled(StyledParagraphMedium)`
  color: ${(props) => props.theme.fontColorFaded};
`;
const ExtraTextSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #ddd;
`;
const ExtraTextArea = styled.textarea`
  padding: 15px 25px;
  min-width: 50%;
  min-height: 2em;
  box-sizing: content-box;
`;

interface ServiceProps {
  serviceName: string;
  serviceDescription: string;
  extraText?: string;
  orderScheduled: boolean;
  enabled: boolean;
  updateServiceState: (enabled: boolean, text?: string) => void;
  isLastItem?: boolean;
  extraTextPlaceholder?: string;
}
const Service = ({
  serviceName,
  serviceDescription,
  extraText,
  orderScheduled,
  enabled,
  updateServiceState,
  isLastItem,
  extraTextPlaceholder,
}: ServiceProps): JSX.Element | null => {
  if (orderScheduled && !enabled) {
    return null;
  }
  return (
    <ServiceWrapper isLastItem={Boolean(isLastItem)}>
      <ServiceHeader>
        <StyledParagraphMedium bold>{serviceName}</StyledParagraphMedium>
        {!orderScheduled && (
          <Checkbox
            checkboxId={'receive_updates'}
            checked={enabled}
            onClick={() => updateServiceState(!enabled, extraText)}
          />
        )}
      </ServiceHeader>
      <FadedParagraphMedium>{serviceDescription}</FadedParagraphMedium>
      {extraText && (
        <>
          <ExtraTextSeparator />
          {orderScheduled && <StyledParagraphMedium>{`"${extraText}"`}</StyledParagraphMedium>}
        </>
      )}
      {!orderScheduled && (
        <>
          <ExtraTextSeparator />
          <ExtraTextArea placeholder={extraTextPlaceholder} rows={7} />
        </>
      )}
    </ServiceWrapper>
  );
};

const RowWrapper = styled.div`
  display: flex;
`;

const WaitTryDropoffServiceWrapper = styled(ServiceWrapper)`
  flex: 1;

  &:nth-of-type(2) {
    border-left: none;
  }
`;

const RadioButton = styled.input`
  margin-left: 0;
`;
const Label = styled.label`
  background-color: ${(props) => props.theme.whiteColor};
`;

interface WaitAndTryDropoffServiceProps {
  updateServiceState: (action: servicesReducerAction) => void;
  serviceEnabled: Pick<GetOrderInfoTransformedResponse['services'], 'waitAndTry' | 'dropoff'>;
  orderScheduled: boolean;
  translationsContent: translationsType;
}

const WaitAndTryDropoffService = ({
  translationsContent,
  orderScheduled,
  serviceEnabled,
  updateServiceState,
}: WaitAndTryDropoffServiceProps) => {
  const changeServiceSelection = (service: keyof WaitAndTryDropoffServiceProps['serviceEnabled']) => {
    updateServiceState({
      enabled: service === 'waitAndTry',
      service: 'waitAndTry',
    });
    updateServiceState({
      enabled: service === 'dropoff',
      service: 'dropoff',
    });
  };
  return (
    <RowWrapper>
      {(!orderScheduled || serviceEnabled.waitAndTry.enabled) && (
        <WaitTryDropoffServiceWrapper>
          <ServiceHeader>
            {!orderScheduled && (
              <Label htmlFor={'waitAndTry'}>
                <StyledParagraphMedium bold>{translationsContent.waitAndTry}</StyledParagraphMedium>
              </Label>
            )}
            {orderScheduled && <StyledParagraphMedium bold>{translationsContent.waitAndTry}</StyledParagraphMedium>}
            {!orderScheduled && (
              <RadioButton
                type="radio"
                id="waitAndTry"
                name="waitAndTryDropoff"
                defaultChecked={serviceEnabled.waitAndTry.enabled}
                onClick={() => changeServiceSelection('waitAndTry')}
              />
            )}
          </ServiceHeader>
          <FadedParagraphMedium>{translationsContent.toshiAssistantWaitWhilstDecide}</FadedParagraphMedium>
        </WaitTryDropoffServiceWrapper>
      )}
      {(!orderScheduled || serviceEnabled.dropoff.enabled) && (
        <WaitTryDropoffServiceWrapper>
          <ServiceHeader>
            {!orderScheduled && (
              <Label htmlFor={'dropoff'}>
                <StyledParagraphMedium bold>{translationsContent.dropoff}</StyledParagraphMedium>
              </Label>
            )}
            {orderScheduled && <StyledParagraphMedium bold>{translationsContent.dropoff}</StyledParagraphMedium>}
            {!orderScheduled && (
              <RadioButton
                type="radio"
                id="dropoff"
                name="waitAndTryDropoff"
                defaultChecked={serviceEnabled.dropoff.enabled}
                onClick={() => changeServiceSelection('dropoff')}
              />
            )}
          </ServiceHeader>
          <FadedParagraphMedium>{translationsContent.dropoffDescription}</FadedParagraphMedium>
        </WaitTryDropoffServiceWrapper>
      )}
    </RowWrapper>
  );
};

const ServiceList = styled.div`
  margin-top: 20px;
`;

interface ServicesProps {
  orderScheduled: boolean;
  servicesState: {
    dropoff: {
      enabled: boolean;
    };
    waitAndTry: {
      enabled: boolean;
    };
    inspireMe: {
      enabled: boolean;
      text?: string;
    };
  };
  isGiftedOrder?: boolean;
  updateServiceState: (action: servicesReducerAction) => void;
}
export const Services = ({
  orderScheduled,
  servicesState,
  updateServiceState,
  isGiftedOrder,
}: ServicesProps): JSX.Element | null => {
  const { translationsContent } = useTranslations<translationsType>(translations);

  if (isGiftedOrder && !orderScheduled) {
    return null;
  }

  return (
    <div>
      {!orderScheduled && (
        <StyledParagraphMedium bold>{translationsContent.customiseYourExperience}</StyledParagraphMedium>
      )}
      <StyledParagraphMedium>{translationsContent.yourToshiAssistantWillHelpWith}</StyledParagraphMedium>
      <ServiceList>
        <WaitAndTryDropoffService
          updateServiceState={updateServiceState}
          serviceEnabled={servicesState}
          orderScheduled={orderScheduled}
          translationsContent={translationsContent}
        />
        <Service
          serviceName={translationsContent.inspireMe}
          serviceDescription={translationsContent.inspireMeDescription}
          orderScheduled={orderScheduled}
          extraText={servicesState.inspireMe.text}
          enabled={servicesState.inspireMe.enabled}
          updateServiceState={(enabled, extraText) =>
            updateServiceState({ enabled, service: 'inspireMe', text: extraText })
          }
          extraTextPlaceholder={translationsContent.inspireMeExtraTextPlaceholder}
          isLastItem
        />
      </ServiceList>
    </div>
  );
};
