import handsIcon from '../assets/icons/hands-icon.svg';
import styled from 'styled-components';
import { breakPointLG } from '../globalConfig/styling/breakPoints';

const LogoContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.invertColor};
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
`;

interface BrandLogoProps {
  brandName: string;
  mobile?: boolean;
  brandLogoUrl?: string;
}

const StyledContainer = styled.div<{ mobile?: boolean }>`
  width: 100%;
  background-color: ${(props) => props.theme.whiteColor};
  display: flex;
  justify-content: center;
  max-height: ${(props) => (props.mobile ? '200px' : '400px')};
  min-height: ${(props) => (props.mobile ? '200px' : '300px')};
  align-items: center;
  flex-direction: column;
  ${(props) => (!props.mobile ? 'display: none;' : '')};

  @media (min-width: ${breakPointLG}px) {
    ${(props) => (props.mobile ? 'display: none;' : '')};
    ${(props) => (!props.mobile ? 'display: flex;' : '')};
  }
`;

const Logo = styled.img<{ usingFallback: boolean; mobile?: boolean }>`
  width: ${(props) => (props.mobile ? '130px' : '239px')};
  background-color: ${(props) => props.theme.whiteColor};
  ${(props) => (props.usingFallback ? 'opacity: 0.5;' : '')};
`;

export const BrandLogo = ({ brandName, mobile, brandLogoUrl }: BrandLogoProps): JSX.Element => {
  const brandLogo = brandLogoUrl || handsIcon;

  return (
    <StyledContainer mobile={mobile}>
      <LogoContainer>
        <Logo mobile={mobile} usingFallback={!brandLogoUrl} alt={`Brand Logo for ${brandName}`} src={brandLogo} />
      </LogoContainer>
    </StyledContainer>
  );
};
