import { Redirect, useHistory, useParams } from 'react-router';
import { ColumnForty, ColumnSixty } from '../globalConfig/styling/Columns';
import { StyledWrapper } from '../globalConfig/styling/styledWrapper';
import styled from 'styled-components';
import { BrandLogo } from '../brandLogo/BrandLogo';
import { ScheduleOrderForm } from '../scheduleOrder/ScheduleOrderForm';
import { GiftLabel } from '../giftLabel/GiftLabel';
import { Service } from '../services/Service';
import { ItemList } from '../itemList/ItemList';
import { useTranslations } from '../localisation/translateText';
import { translations, translationsType } from './translations/index';
import { OrderHelp } from '../orderHelp/OrderHelp';
import { StyledParagraph, StyledParagraphLarge, SpanBold } from '../common/typography/Typography';
import { useGetData, useMutateData } from '../network/api/api';
import {
  getOrderInfoEndpoint,
  GetOrderInfoResponse,
  GetOrderInfoTransformedResponse,
  SubmitScheduleGiftFormBody,
  submitScheduleGiftFormEndpoint,
  SubmitScheduleGiftFormResponse,
} from '../network/api/endpoints';
import { OrderInfoErrorPage } from '../common/OrderInfoErrorPage';
import { transformOrderDetails } from '../network/transformers/transformOrderDetails';
import { breakPointLG } from '../globalConfig/styling/breakPoints';
import { ComponentProps, useCallback, useState } from 'react';
import { dateFormats, formatDate, parseDate } from '../dates/dateFormatting';
import { GetAvailabilityTransformedData } from '../network/transformers/transformAvailabilityInfo';
import { LoadingSpinner } from '../common/LoadingSpinner';

const LeftContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPointLG}px) {
    max-width: 740px;
  }
`;

const RightContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakPointLG}px) {
    max-width: 740px;
  }
`;

const PaddingContainer = styled.div`
  padding-bottom: 20px;
`;

const OrderHelpWrapper = styled.div`
  @media (min-width: ${breakPointLG}px) {
    margin-top: 41px;
  }
`;

export const ReceiveGift = (): JSX.Element | null => {
  const history = useHistory();
  const { translationsContent, getTranslationWithValues } = useTranslations<translationsType>(translations);
  const urlParams = useParams<{ id: string }>();
  const { data, error } = useGetData<GetOrderInfoTransformedResponse, GetOrderInfoResponse>(
    `${getOrderInfoEndpoint}/${urlParams.id}`,
    (value) => transformOrderDetails(value),
  );
  const { mutate } = useMutateData();

  const [submitError, setSubmitError] = useState(false);
  const [availabilityData, setAvailabilityData] = useState<GetAvailabilityTransformedData>();

  interface FormInput {
    delivery_date: string;
    delivery_slot_id: string;
    line1: string;
    line2?: string;
    city?: string;
    postcode: string;
    receive_updates: boolean;
  }
  const onSubmit: ComponentProps<typeof ScheduleOrderForm>['onSubmit'] = useCallback(
    async (_data) => {
      const data = _data as typeof _data & FormInput;
      const scheduleOrderBody: SubmitScheduleGiftFormBody = {
        ..._data,
        delivery_slot_id: data.delivery_slot_id,
        scheduled_date: data.delivery_date,
        order_id: urlParams.id,
        address: {
          line_1: data.line1,
          line_2: data.line2,
          city_town: data.city,
          postcode_zipcode: data.postcode,
        },
      };

      const scheduleOrderResponse = await mutate<SubmitScheduleGiftFormResponse>(
        submitScheduleGiftFormEndpoint(urlParams.id),
        scheduleOrderBody,
        'POST',
      );

      if ('error' in scheduleOrderResponse) {
        setSubmitError(true);
      } else {
        const deliverySlotsForDate = availabilityData?.dates[data.delivery_date];
        if (availabilityData && data.delivery_date && deliverySlotsForDate) {
          const deliverySlot = deliverySlotsForDate.filter(
            (slot) => String(slot.deliverySlotId) === String(data.delivery_slot_id),
          );
          history.push('/orderComplete', {
            bookingDate: data.delivery_date,
            bookingTimeslotStart: formatDate(
              parseDate(deliverySlot[0].startTime, dateFormats.longDateFormat),
              dateFormats.hourMinute,
            ),
            bookingTimeslotEnd: formatDate(
              parseDate(deliverySlot[0].endTime, dateFormats.longDateFormat),
              dateFormats.hourMinute,
            ),
            deliveryAddress: [data.line1, data.line2, data.city, data.postcode].join(' '),
          });
        }
      }
    },
    [availabilityData, history, mutate, urlParams.id],
  );

  if (error && Object.keys(error).length > 0) {
    return <OrderInfoErrorPage />;
  }

  if (!data) {
    return <LoadingSpinner />;
  }

  if (data.deliveryInfo) {
    return <Redirect to={`/orders/${urlParams.id}`} />;
  }

  if (!data.giftReceiver) {
    return <Redirect to={`/orders/${urlParams.id}/book`} />;
  }

  return (
    <>
      <BrandLogo mobile brandName={data.brandName} />
      <StyledWrapper>
        <ColumnForty>
          <LeftContainer>
            <BrandLogo brandName={data.brandName} brandLogoUrl={data.brandLogoUrl} />
            <Service />
            {data.deliveryInfo ? <ItemList items={data.orderItems} /> : null}
            <OrderHelpWrapper>
              <OrderHelp />
            </OrderHelpWrapper>
          </LeftContainer>
        </ColumnForty>
        <ColumnSixty>
          <RightContainer>
            <PaddingContainer>
              <StyledParagraphLarge>
                {getTranslationWithValues(translationsContent.customerAddressorHello, [data.giftReceiver])}
              </StyledParagraphLarge>
            </PaddingContainer>
            <PaddingContainer>
              <GiftLabel giftMessage={data.giftMessage} giftSender={data.giftSender} brandName={data.brandName} />
            </PaddingContainer>
            <PaddingContainer>
              <StyledParagraphLarge>
                {translationsContent.pleaseBookSentencePt1} <SpanBold>{data.brandName}</SpanBold>{' '}
                {translationsContent.pleaseBookSentencePt2}
              </StyledParagraphLarge>
            </PaddingContainer>
            <StyledParagraph>
              {data.storeLocation === 'NYC'
                ? translationsContent.toshiAssistantSentenceNYC
                : translationsContent.toshiAssistantSentenceLDN}
            </StyledParagraph>
            <ScheduleOrderForm
              storeId={data.storeId}
              orderDetails={data}
              orderId={urlParams.id}
              onSubmit={onSubmit}
              submitError={submitError}
              availabilityData={availabilityData}
              setAvailabilityData={setAvailabilityData}
            />
          </RightContainer>
        </ColumnSixty>
      </StyledWrapper>
    </>
  );
};
