import { createGlobalStyle } from 'styled-components';
import woff2 from './1846241fbfa2b978f919cb6d3ac51df7.woff2';
import woff from './1846241fbfa2b978f919cb6d3ac51df7.woff';
import ttf from './1846241fbfa2b978f919cb6d3ac51df7.ttf';

export const markOTHeavyFontFace = `@font-face {
  font-family: MarkOT;
  font-weight: 800;
  src: url("${woff2}") format("woff2"),
  url("${woff}") format("woff"),
  url("${ttf}") format("truetype");
}`;

export const globalFontStyle = createGlobalStyle`
  ${markOTHeavyFontFace}
`;
