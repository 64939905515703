import { useEffect } from 'react';

export const useDebouncedEffect = (
  effect: React.EffectCallback,
  deps: React.DependencyList | undefined,
  delay: number,
): void => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};
