import { GetAvailabilityResponse } from '../api/endpoints';

export type GetAvailabilityTransformedDates = {
  [key: string]:
    | {
        deliverySlotId: number;
        startTime: string;
        endTime: string;
        deliveryDate: string;
        available: boolean;
      }[]
    | null;
};

export type GetAvailabilityTransformedData = {
  available: boolean;
  orderHoldMinutes: number;
  dates: GetAvailabilityTransformedDates;
};

export const transformAvailabilityInfo = (response: GetAvailabilityResponse): GetAvailabilityTransformedData => {
  const transformedDates: GetAvailabilityTransformedDates = {};

  Object.entries(response.dates).forEach(([dateString, dateValue]) => {
    transformedDates[dateString] = dateValue.map((value) => ({
      available: value.available,
      deliveryDate: value.delivery_date,
      startTime: value.start_time,
      endTime: value.end_time,
      deliverySlotId: value.delivery_slot_id,
    }));
  });
  return {
    available: response.available,
    orderHoldMinutes: response.order_hold_minutes,
    dates: transformedDates,
  };
};
