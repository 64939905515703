import { UserIcon as HeroIconsUser } from '@heroicons/react/outline';
import styled from 'styled-components';
import { StyledParagraphMedium } from '../common/typography/Typography';
import { AnyJourneyTransitionStatus, JourneyTransitionTransformedResponse } from '../network/api/endpoints';
import { GridWrapper, StatusUpdate } from './SharedStyledComponents';
import { translationsType } from './translations';

const statusAchieved = (
  transitions: JourneyTransitionTransformedResponse[],
  status: AnyJourneyTransitionStatus,
): boolean => {
  return transitions.filter((transition) => transition.toState === status).length === 1;
};

const CircleHolder = styled.div<{ noPadding?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:nth-child(3) {
    ${(props) => (props.noPadding ? '' : 'padding-top: 10px;')}
  }

  &:nth-child(5) {
    margin-top: -3px;
  }

  &:nth-last-child(2) {
    ${(props) => (props.noPadding ? '' : 'padding-bottom: 10px;')}
    margin-top: -3px;
  }
`;
const Circle = styled.div`
  width: 12px;
  height: 12px;
  border: ${(props) => `1px solid ${props.theme.fontColorFaded}`};
  border-radius: 100%;
  margin-left: 1px;
`;
const OuterLine = styled.div`
  width: 1px;
  background: ${(props) => props.theme.fontColorFaded};
  height: calc(100% - 12px);
`;
const InnerLine = styled.div`
  width: 1px;
  background: ${(props) => props.theme.fontColorFaded};
  height: calc((100% - 12px) / 2);
`;
const UpdatesToAppearBox = styled.div`
  border: 1px solid #ddd;
  color: ${(props) => props.theme.fontColorFaded};
  padding: 15px;
`;
const UserIcon = styled(HeroIconsUser)`
  align-self: center;
  stroke: ${(props) => props.theme.fontColorFaded};
  margin-right: 10px;
  width: 20px;
  height: 20px;
`;

interface ArrivedAtStoreCircleLinesProps {
  transitions: JourneyTransitionTransformedResponse[];
}
const ArrivedAtStoreCircleLines = ({ transitions }: ArrivedAtStoreCircleLinesProps) => {
  if (statusAchieved(transitions, 'collected_items')) {
    return (
      <CircleHolder>
        <Circle />
        <OuterLine />
      </CircleHolder>
    );
  }

  return (
    <CircleHolder noPadding>
      <Circle />
    </CircleHolder>
  );
};

interface CollectedItemsCircleLinesProps {
  transitions: JourneyTransitionTransformedResponse[];
}
const CollectedItemsCircleLines = ({ transitions }: CollectedItemsCircleLinesProps) => {
  if (statusAchieved(transitions, 'completed')) {
    return (
      <CircleHolder>
        <InnerLine />
        <Circle />
        <InnerLine />
      </CircleHolder>
    );
  }

  return (
    <CircleHolder>
      <OuterLine />
      <Circle />
    </CircleHolder>
  );
};

const YourAssistantUpdates = styled(StyledParagraphMedium)<{ faded: boolean }>`
  ${(props) => (props.faded ? `color: ${props.theme.fontColorFaded}` : '')};
`;

interface ToshiAssistantUpdatesProps {
  translations: translationsType;
  transitions?: JourneyTransitionTransformedResponse[];
}

export const ToshiAssistantUpdates = ({ translations, transitions }: ToshiAssistantUpdatesProps): JSX.Element => {
  const lastTransition = transitions && transitions[transitions.length - 1];
  const hasJourney = transitions !== undefined && lastTransition !== undefined;
  const journeyNotStartedYet = !hasJourney || (lastTransition?.toState === 'scheduled' && lastTransition?.mostRecent);

  return (
    <GridWrapper rowGap={journeyNotStartedYet ? 10 : undefined} collapseLeftCol>
      <UserIcon aria-label="Assistant icon" />
      <YourAssistantUpdates faded={journeyNotStartedYet}>{translations.yourToshiAssistantUpdates}</YourAssistantUpdates>
      {journeyNotStartedYet && (
        <>
          <div />
          <UpdatesToAppearBox>{translations.realtimeUpdatesWillAppear}</UpdatesToAppearBox>
        </>
      )}
      {hasJourney && statusAchieved(transitions, 'arrived_at_store') && (
        <>
          <ArrivedAtStoreCircleLines transitions={transitions} />
          <StatusUpdate faded={lastTransition.toState !== 'arrived_at_store'}>{translations.atTheStore}</StatusUpdate>
        </>
      )}
      {hasJourney && statusAchieved(transitions, 'collected_items') && (
        <>
          <CollectedItemsCircleLines transitions={transitions} />
          <StatusUpdate faded={lastTransition.toState !== 'collected_items'}>{translations.onTheWay}</StatusUpdate>
        </>
      )}
      {hasJourney && statusAchieved(transitions, 'completed') && (
        <>
          <CircleHolder>
            <OuterLine />
            <Circle />
          </CircleHolder>
          <StatusUpdate faded>{translations.deliveredYourItems}</StatusUpdate>
        </>
      )}
    </GridWrapper>
  );
};
