import styled from 'styled-components';
import { Checkbox } from './Checkbox';
import chevronDown from '../../assets/icons/chevronDown.svg';

const StyledInput = styled.input`
  background: ${(props) => props.theme.whiteColor};
  border: 1px solid ${(props) => props.theme.blackColor};
  box-sizing: border-box;
  padding: 12px 24px 14px;
  margin: 0;
  ${(props) => (props.disabled ? `background: ${props.theme.lightGrey};` : '')}
`;

const StyledSelect = styled.select`
  border: 1px solid ${(props) => props.theme.blackColor};
  box-sizing: border-box;
  padding: 12px 24px 14px;
  appearance: none;
  text-indent: 1px;
  border-radius: 0;
  background: url(${chevronDown}) no-repeat;
  background-position: calc(100% - 0.75rem) center;
  background-color: ${(props) => props.theme.whiteColor};
  padding-right: 2rem;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
`;

export { StyledLabel, StyledSelect, StyledInput, Checkbox };
