import { GetOrderInfoResponse, GetOrderInfoTransformedResponse } from '../api/endpoints';
import { camelizeObject, transformPersonResponse } from './standardTransformers';
import { differenceInSeconds } from 'date-fns';

export const transformOrderDetails = (orderResponse: GetOrderInfoResponse): GetOrderInfoTransformedResponse => {
  const camelised = camelizeObject(orderResponse.order) as GetOrderInfoTransformedResponse;
  const customer: GetOrderInfoTransformedResponse['customer'] = {
    ...camelised.customer,
    name: transformPersonResponse(orderResponse.order.customer),
  };
  return {
    ...camelised,
    giftReceiver: orderResponse.order.gift_receiver && transformPersonResponse(orderResponse.order.gift_receiver),
    giftSender: orderResponse.order.gift_sender && transformPersonResponse(orderResponse.order.gift_sender),
    customer: customer,
    journeys: [...(camelised.journeys ?? [])]
      .map((journey) => {
        return {
          ...journey,
          journeyTransitions: [...journey.journeyTransitions].sort((transA, transB) => transA.sortKey - transB.sortKey),
        };
      })
      .sort((journeyA, journeyB) =>
        differenceInSeconds(new Date(journeyA.startDatetime), new Date(journeyB.startDatetime)),
      ),
  } as GetOrderInfoTransformedResponse;
};
