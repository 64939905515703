import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { Redirect, useHistory, useParams } from 'react-router';
import { FullWidthPaddedColumn } from '../common/Containers';
import { LoadingSpinner } from '../common/LoadingSpinner';
import { StyledParagraphLarge } from '../common/typography/Typography';
import { useTranslations } from '../localisation/translateText';
import { useGetData, useMutateData } from '../network/api/api';
import {
  bookerBody,
  bookerEndpoint,
  BookerResponse,
  getOrderInfoEndpoint,
  GetOrderInfoResponse,
  GetOrderInfoTransformedResponse,
} from '../network/api/endpoints';
import { GetAvailabilityTransformedData } from '../network/transformers/transformAvailabilityInfo';
import { transformOrderDetails } from '../network/transformers/transformOrderDetails';
import { OrderInfoErrorPage } from '../common/OrderInfoErrorPage';
import { ScheduleOrderForm } from '../scheduleOrder/ScheduleOrderForm';
import { translations, translationsType } from './translations';
import { breakPoint2XL, breakPointLG } from '../globalConfig/styling/breakPoints';
import useMatchMedia from 'use-match-media-hook';
import { BrandLogo } from '../brandLogo/BrandLogo';
import { OrderItems } from '../common/orderItems/OrderItems';
import { OrderHelp } from '../orderHelp/OrderHelp';

const IntroTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 70px 70px 0 150px;
  gap: 240px;
`;

const OrderHelpWrapper = styled.div`
  margin-top: 20px;
`;

const DesktopLeftSection = styled.div`
  flex: 0.7;

  @media (min-width: ${breakPoint2XL}px) {
    flex: 0.6;
  }
`;

const DesktopRightSection = styled.div`
  flex: 0.25;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BottomSection = styled.div`
  margin-top: 20px;
  border-top: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const BookOrder = (): JSX.Element | null => {
  const urlParams = useParams<{ id: string }>();
  const { data: orderData, error } = useGetData<GetOrderInfoTransformedResponse, GetOrderInfoResponse>(
    `${getOrderInfoEndpoint}/${urlParams.id}`,
    (value) => transformOrderDetails(value),
  );
  const { translationsContent } = useTranslations<translationsType>(translations);
  const { mutate } = useMutateData();
  const history = useHistory();

  const [submitError, setSubmitError] = useState(false);
  const [availabilityData, setAvailabilityData] = useState<GetAvailabilityTransformedData>();

  interface FormInput {
    delivery_date: string;
    delivery_slot_id: string;
    services: GetOrderInfoTransformedResponse['services'];
    receive_updates?: boolean;
  }
  const onSubmit: SubmitHandler<FieldValues> = useCallback(
    async (_data) => {
      const data = _data as typeof _data & FormInput;
      const bookOrderBody: bookerBody = {
        delivery_journey: {
          delivery_date: data.delivery_date,
          delivery_slot_id: data.delivery_slot_id,
        },
        services: {
          wait_and_try: data.services.waitAndTry,
          dropoff: data.services.dropoff,
          inspire_me: data.services.inspireMe,
        },
      };

      const bookOrderResponse = await mutate<BookerResponse>(bookerEndpoint(urlParams.id), bookOrderBody, 'POST');

      if ('error' in bookOrderResponse) {
        setSubmitError(true);
      } else {
        history.push(`/orders/${urlParams.id}`);
      }
    },
    [history, mutate, urlParams.id],
  );

  const queries = [`(min-width: ${breakPointLG}px)`];
  const [desktop] = useMatchMedia(queries);

  if (error && Object.keys(error).length > 0) {
    return <OrderInfoErrorPage />;
  }

  if (!orderData) {
    return <LoadingSpinner />;
  }

  if (orderData.journeys?.length) {
    return <Redirect to={`/orders/${urlParams.id}`} />;
  }

  if (orderData.giftReceiver) {
    return <Redirect to={`/gifting/${urlParams.id}`} />;
  }

  if (desktop) {
    return (
      <DesktopWrapper>
        <DesktopLeftSection>
          <IntroTextContainer>
            <StyledParagraphLarge bold>{orderData.brandName}</StyledParagraphLarge>
            <StyledParagraphLarge>{`${translationsContent.hello} ${orderData.customer.name},`}</StyledParagraphLarge>
            <StyledParagraphLarge>{translationsContent.pleaseBookConvenientDateTimeForDelivery}</StyledParagraphLarge>
          </IntroTextContainer>
          <ScheduleOrderForm
            orderDetails={orderData}
            orderId={orderData.id}
            storeId={orderData.storeId}
            onSubmit={onSubmit}
            submitError={submitError}
            availabilityData={availabilityData}
            setAvailabilityData={setAvailabilityData}
          />
        </DesktopLeftSection>
        <DesktopRightSection>
          <BrandLogo brandName={orderData.brandName} brandLogoUrl={orderData.brandLogoUrl} />
          <OrderItems orderData={orderData} />
          <OrderHelpWrapper>
            <OrderHelp />
          </OrderHelpWrapper>
        </DesktopRightSection>
      </DesktopWrapper>
    );
  }
  return (
    <>
      <FullWidthPaddedColumn>
        <IntroTextContainer>
          <StyledParagraphLarge bold>{orderData.brandName}</StyledParagraphLarge>
          <StyledParagraphLarge>{`${translationsContent.hello} ${orderData.customer.name},`}</StyledParagraphLarge>
          <StyledParagraphLarge>{translationsContent.pleaseBookConvenientDateTimeForDelivery}</StyledParagraphLarge>
        </IntroTextContainer>
        <ScheduleOrderForm
          orderDetails={orderData}
          orderId={orderData.id}
          storeId={orderData.storeId}
          onSubmit={onSubmit}
          submitError={submitError}
          availabilityData={availabilityData}
          setAvailabilityData={setAvailabilityData}
        />
      </FullWidthPaddedColumn>
      <BottomSection>
        <OrderItems orderData={orderData} />
        <OrderHelp />
      </BottomSection>
    </>
  );
};
